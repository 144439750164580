export default function tableConfig() {
  const IF_ID_SCAN_VALID_HAS_KEY_IDENTIFY = 'profiles'
  const INVALID_DATA_ERRORS = 'error'

  const generalInformation = {
    id: 'general-information',
    title: '',
    getFromThisObject: 'profiles',
    labelKeyArr: [
      {
        label: 'User names',
        key: 'verificationResult',
      },
    ],
    identiFraudChild: [
      {
        keyName: 'minorResult',
        label: 'Minor Result',
        notFoundMessage: 'The matched consumer is an adult.',
      },
      {
        keyName: 'dateOfBirth',
        label: 'Birthdate',
        notFoundMessage: 'No match to name or address.',
      },
      {
        keyName: 'dateOfBirthResult',
        label: 'Birthdate Result',
        notFoundMessage: 'Consumer not on file – no record.',
      },
    ],
    identiFraudChildSkipped: [
      {
        keyName: 'ssnResult',
        label: 'SSN',
        notFoundMessage: 'Social Security Number was not supplied on search request Header file searched by name and address with no match found.',
        titleOfFraud: '',
      },
      {
        keyName: 'reportedFraudResult',
        label: 'Fraud Result',
        notFoundMessage: 'No fraud has been reported for the matched consumer.',
        titleOfFraud: 'Validation Status',
      },
    ],
  }

  const mixedDataScore = {
    id: 'general-information-mixed',
    title: 'Address',
    getFromThisObject: 'profiles',
    getParentObject: 'verificationResult',
    scoredData: [
      {
        fieldName: 'Address',
        label: 'Address Score',
      },
      {
        fieldName: 'City',
        label: 'City Score',
      },
      {
        fieldName: 'State',
        label: 'State Score',
      },
      {
        fieldName: 'Zip',
        label: 'Zip Score',
      },
      {
        fieldName: 'Country',
        label: 'Country Score',
      },
    ],
    dataStore: [
      {
        key: 'addressVerificationResult',
        label: 'Address Result',
      },
      {
        key: 'addressTypeResult',
        label: 'Address Type',
      },
      {
        key: 'addressHighRiskResult',
        label: 'High Risk Result',
      },
    ],
  }

  return {
    mixedDataScore,
    generalInformation,
    INVALID_DATA_ERRORS,
    IF_ID_SCAN_VALID_HAS_KEY_IDENTIFY,
  }
}
