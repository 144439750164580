<template>
  <b-row>
    <b-col
      v-for="field in ['comments', 'status']"
      :key="field"
      md="6"
    >
      <component
        :is="fields[field].type"
        :ref="`${field}_ref`"
        v-model="verification[field]"
        class="verification-comments"
        v-bind="getProps(field)"
        :is-editable="!$can(ACCESS_ABILITY_VERIFICATION.action, ACCESS_ABILITY_VERIFICATION.subject) || isEditable"
      />
    </b-col>
  </b-row>
</template>

<script>
// Components
import {
  BCol, BRow, BBadge,
} from 'bootstrap-vue'

// Configs
import config from '@/views/main/customers/view/verification/verificationConfigs'

export default {
  name: 'FormBottom',
  components: {
    BCol,
    BRow,
    BBadge,
  },
  props: {
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    verification() {
      return this.$store.state[this.MODULE_NAME].verificationForm
    },
  },
  methods: {
    getProps(fieldName) {
      return {
        is: this.fields[fieldName].type,
        field: this.fields[fieldName],
        name: fieldName,
      }
    },
  },
  setup() {
    const MODULE_NAME = 'verification'

    const { fields, ACCESS_ABILITY_VERIFICATION } = config()
    return {
      fields,
      MODULE_NAME,
      ACCESS_ABILITY_VERIFICATION,
    }
  },
}
</script>
