var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"10px","margin-bottom":"8px"}},[_c('feather-icon',{class:("" + (_vm.checkVerificationStatus() === 'LNoIcon' ? 'customer-status-icon':'')),attrs:{"icon":_vm.checkVerificationStatus(),"size":"16"}}),_c('h5',{staticClass:"font-weight-bolder mb-0"},[_vm._v(" "+_vm._s(_vm.$t('ID Scan Criminal Record'))+" ")])],1),(!_vm.verification.customer.verification || !_vm.IDScanCriminal)?_c('div',{staticClass:"d-flex justify-content-center mb-2 border align-items-center",staticStyle:{"border-radius":"5px","height":"36px"}},[_c('p',{staticClass:"d-flex align-items-center mb-0 text-warning",staticStyle:{"gap":"8px","opacity":"0.5"}},[_c('span',[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t('not validated yet')))+" ")])])]):_c('b-card',{class:("" + ((_vm.IDScanCriminal && _vm.IDScanCriminal[_vm.IF_ID_SCAN_VALID_HAS_KEY_CRIMINAL]) ? 'adscan-card-criminal' : '')),attrs:{"no-body":""}},[((_vm.IDScanCriminal && _vm.IDScanCriminal[_vm.IF_ID_SCAN_VALID_HAS_KEY_CRIMINAL][0][_vm.OFFENSE_OBJECT_NAME]))?_c('b-row',_vm._l(([_vm.criminalInformation]),function(ref){
var title = ref.title;
var items = ref.items;
return _c('b-col',{key:title,attrs:{"cols":"12"}},_vm._l(((_vm.IDScanCriminal[_vm.IF_ID_SCAN_VALID_HAS_KEY_CRIMINAL][0][_vm.OFFENSE_OBJECT_NAME].length)),function(num){return _c('div',{key:num,staticClass:"mb-2"},[_c('h6',{staticClass:"font-weight-bolder mb-2"},[_c('span',[_vm._v(_vm._s(title))]),_c('span',[_vm._v(" — ")]),_c('span',[_vm._v(_vm._s(_vm.IDScanCriminal[_vm.IF_ID_SCAN_VALID_HAS_KEY_CRIMINAL][0][_vm.OFFENSE_OBJECT_NAME][num-1] ? _vm.IDScanCriminal[_vm.IF_ID_SCAN_VALID_HAS_KEY_CRIMINAL][0][_vm.OFFENSE_OBJECT_NAME][num-1]['title'] : _vm.$t('Not Specified')))])]),_vm._l((items),function(ref){
var label = ref.label;
var key = ref.key;
return _c('div',{key:key,staticClass:"px-1"},[(key !== 'title')?_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('b',[_vm._v(_vm._s(label))])]),_c('b-col',{attrs:{"cols":"9"}},[_c('div',[_vm._v(" "+_vm._s(_vm.IDScanCriminal[_vm.IF_ID_SCAN_VALID_HAS_KEY_CRIMINAL][0][_vm.OFFENSE_OBJECT_NAME][num-1][key] ? _vm.IDScanCriminal[_vm.IF_ID_SCAN_VALID_HAS_KEY_CRIMINAL][0][_vm.OFFENSE_OBJECT_NAME][num-1][key] : 'NO')+" ")])])],1):_vm._e()],1)})],2)}),0)}),1):_vm._e(),((_vm.IDScanCriminal && _vm.IDScanCriminal[_vm.INVALID_DATA_ERRORS]))?_c('div',[_c('div',{staticClass:"d-flex justify-content-center mb-2 border align-items-center",staticStyle:{"border-radius":"5px","height":"36px"}},[_c('p',{staticClass:"d-flex align-items-center mb-0 text-danger",staticStyle:{"gap":"8px","opacity":"0.8"}},_vm._l((_vm.IDScanCriminal[_vm.INVALID_DATA_ERRORS]),function(ref,idx){
var message = ref.message;
return _c('span',{key:idx},[_vm._v(" "+_vm._s(_vm._f("capitalize")(message))+" "),(idx !== _vm.IDScanCriminal[_vm.INVALID_DATA_ERRORS].length - 1)?_c('span',[_vm._v(",")]):_vm._e()])}),0)])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }