export default function tableConfig() {
  const IF_ID_SCAN_VALID_HAS_KEY_CRIMINAL = 'profiles'
  const OFFENSE_OBJECT_NAME = 'offenses'
  const INVALID_DATA_ERRORS = 'error'

  const criminalInformation = {
    title: 'Offense',
    items: [
      {
        label: 'TitleName',
        key: 'title',
      },
      {
        label: 'Description',
        key: 'description',
      },
      {
        label: 'Case Number',
        key: 'caseNumber',
      },
      {
        label: 'Jurisdiction',
        key: 'jurisdiction',
      },
      {
        label: 'Arresting Agency',
        key: 'arrestingAgency',
      },
      {
        label: 'Warrant Date',
        key: 'dateOfWarrant',
      },
      {
        label: 'Disposition',
        key: 'disposition',
      },
      {
        label: 'Disposition Date',
        key: 'dispositionDate',
      },
    ],
  }

  return {
    criminalInformation,
    INVALID_DATA_ERRORS,
    OFFENSE_OBJECT_NAME,
    IF_ID_SCAN_VALID_HAS_KEY_CRIMINAL,
  }
}
