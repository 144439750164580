var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"10px","margin-bottom":"8px"}},[_c('feather-icon',{class:("" + (_vm.checkVerificationStatus() === 'LNoIcon' ? 'customer-status-icon':'')),attrs:{"icon":_vm.checkVerificationStatus(),"size":"16"}}),_c('h5',{staticClass:"font-weight-bolder mb-0"},[_vm._v(" "+_vm._s(_vm.$t('ID Scan DMV Check'))+" ")])],1),(!_vm.verification.customer.verification || !_vm.IDScanDMV)?_c('div',{staticClass:"d-flex justify-content-center mb-2 border align-items-center",staticStyle:{"border-radius":"5px","height":"36px"}},[_c('p',{staticClass:"d-flex align-items-center mb-0 text-warning",staticStyle:{"gap":"8px","opacity":"0.5"}},[_c('span',[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t('not validated yet')))+" ")])])]):_c('b-card',{class:("" + ((_vm.IDScanDMV && _vm.IDScanDMV[_vm.IF_ID_SCAN_VALID_HAS_KEY]) ? 'adscan-card' : '')),attrs:{"no-body":""}},[((_vm.IDScanDMV && _vm.IDScanDMV[_vm.IF_ID_SCAN_VALID_HAS_KEY]))?_c('b-row',_vm._l(([_vm.personalInformation, _vm.addressInID, _vm.validationStatus, _vm.IDInformation]),function(ref){
var id = ref.id;
var title = ref.title;
var labelKeyArr = ref.labelKeyArr;
var getFromThisObject = ref.getFromThisObject;
var getFromExtraThisObject = ref.getFromExtraThisObject;
return _c('b-col',{key:id,attrs:{"cols":"6"}},[_c('div',{staticClass:" mb-2"},[_c('h6',{staticClass:"font-weight-bolder mb-2"},[_vm._v(" "+_vm._s(_vm.$t(title))+" ")]),_vm._l((labelKeyArr),function(ref){
var label = ref.label;
var key = ref.key;
return _c('div',{key:key,staticClass:"px-1"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b',[_vm._v(_vm._s(label))])]),_c('b-col',{attrs:{"cols":"6"}},[_c('div',[(id === 'validation-status')?_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.IDScanDMV[getFromThisObject][key] ? 'Yes' : 'No')))]):_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.IDScanDMV[getFromThisObject][key] ? _vm.IDScanDMV[getFromThisObject][key] : '---')))])])])],1)],1)}),(getFromExtraThisObject)?_c('div',{staticClass:"px-1"},_vm._l((_vm.IDScanDMV[getFromExtraThisObject]),function(obj,idx){return _c('div',{key:idx},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(obj.displayName)+" "+_vm._s(_vm.$t('Validation'))+" ")]),_c('div',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t('Reason'))+" ")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('div',[_vm._v(_vm._s(obj.statusString ? obj.statusString : '---')+" ")]),_c('div',[_vm._v(_vm._s(obj.reason ? _vm.splitReason(obj.reason) : '---')+" ")])])],1)],1)}),0):_vm._e()],2)])}),1):_vm._e(),(_vm.IDScanDMV && _vm.IDScanDMV[_vm.INVALID_DATA_ERRORS].length > 0)?_c('div',[_c('div',{staticClass:"d-flex justify-content-center mb-2 border align-items-center",staticStyle:{"border-radius":"5px","height":"36px"}},[_c('p',{staticClass:"d-flex align-items-center mb-0 text-danger",staticStyle:{"gap":"8px","opacity":"0.8"}},_vm._l((_vm.IDScanDMV[_vm.INVALID_DATA_ERRORS]),function(ref,idx){
var message = ref.message;
return _c('span',{key:idx},[_vm._v(" "+_vm._s(_vm._f("capitalize")(message))+" "),(idx !== _vm.IDScanDMV[_vm.INVALID_DATA_ERRORS].length - 1)?_c('span',[_vm._v(",")]):_vm._e()])}),0)])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }