<template>
  <div>
    <error-alert
      :fields="fields"
      :error="error"
    />
    <b-overlay
      :show="isChecking"
      rounded="sm"
    >
      <template #overlay>
        <div class="d-flex flex-column align-items-center justify-center">
          <h2 class="mb-2">
            {{ $t('Checking...') }}
          </h2>
          <b-spinner variant="secondary" />
        </div>
      </template>
      <b-form class="verification-form">
        <validation-observer
          ref="customerVerificationVal"
        >
          <div class="d-flex justify-content-end">
            <feather-icon
              v-if="organizationRequiresEditConfirmation"
              v-b-tooltip.noninteractive.hover.bottom
              :title="$t('Edit')"
              :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
              size="36"
              class="cursor-pointer"
              @click="isEditAble()"
            />
          </div>
          <verification-rules
            class="mb-1"
            :is-editable="isEdit"
          />
          <insurance-policy :is-editable="isEdit" />
          <b-row class="mb-1">
            <account-holder-information class="mb-1" />
          </b-row>
          <verification-results-tables />
          <form-bottom :is-editable="isEdit" />
          <portal to="body-footer">
            <div class="d-flex mt-2 pb-1 justify-content-between">
              <div>
                <b-button
                  v-if="!isFormChanged"
                  class="cancelBtn font-medium-1 font-weight-bolder"
                  variant="outline-primary"
                  @click="$router.push({name: 'home-customers-list'})"
                >
                  {{ $t('Back to List') }}
                </b-button>
                <b-button
                  v-else
                  class="cancelBtn font-medium-1 font-weight-bolder"
                  variant="outline-primary"
                  @click="cancel()"
                >
                  <feather-icon
                    icon="LCancelIcon"
                    size="16"
                  />
                  {{ $t('Cancel') }}
                </b-button>
              </div>
              <div>
                <b-button
                  variant="secondary"
                  class="saveBtn font-medium-1 font-weight-bolder mr-2"
                  @click="showModal('show-request-documentation-modal')"
                >

                  {{ $t('Request Documents') }}
                </b-button>
                <b-button
                  variant="secondary"
                  class="saveBtn font-medium-1 font-weight-bolder mr-2"
                  :disabled="isQueryChecking"
                  @click="queryServiceCheck"
                >
                  <b-spinner
                    v-if="isQueryChecking"
                    small
                  />
                  <span v-else>{{ $t('Query Services') }}</span>
                </b-button>
                <b-button
                  variant="success"
                  class="saveBtn font-medium-1 font-weight-bolder"
                  :disabled="!isFormChanged || isSubmitting"
                  style="width: 200px"
                  @click="submit()"
                >
                  <span class="d-flex align-items-center justify-content-center">
                    <b-spinner
                      v-if="isSubmitting"
                      small
                    />
                    <span v-else>{{ $t('Save') }}</span>
                  </span>
                </b-button>
              </div>
            </div>
          </portal>
        </validation-observer>
      </b-form>
    </b-overlay>

    <b-s-warning-modal ref="confirm-modal" />
    <b-modal
      id="show-request-documentation-modal"
      ref="show-request-documentation-modal"
      centered
      hide-header
      body-class="bs-warning-modal__body modal-bg-color"
      footer-class="show-contact-types-modal__footer justify-content-between border-top-0 modal-bg-color pb-2"
      ok-variant="success"
      cancel-variant="outline-primary"
      size="md"
      @ok="onOk"
      @hidden="resetModalForm"
    >
      <div class="mt-1">
        <b-row>
          <b-col cols="12">
            <h3 class="l-modal__title">
              {{ $t('Request Documentation') }}
            </h3>
            <p class="text-center">
              {{ $t('Please select what form(s) of documentation you’d like to request:' ) }}
            </p>
            <div
              v-for="{id, label } in documentIdOptions"
              :key="id"
              class="d-flex align-content-center mb-2"
            >
              <b-form-checkbox
                :id="id.toString()"
                ref="checkboxRefs"
                v-model="checkedItems.checkedIds[id]"
                class="d-flex align-items-center"
              /><label
                :for="id.toString()"
                class="inputLabelNormal d-flex align-items-end"
              >{{ label }}</label>
            </div>
            <div>
              <label
                for="text-message"
                class="inputLabelNormal d-flex align-items-end"
              >{{ $t('Message') }}</label>
              <b-form-textarea
                id="text-message"
                v-model="checkedItems.message"
                rows="3"
                placeholder="Write notes here..."
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <template
        #modal-footer="{ ok, cancel}"
      >
        <b-button
          class="cancelBtn px-3 font-medium-1 font-weight-bolder"
          variant="outline-primary"
          @click="cancel()"
        >
          <feather-icon
            icon="LCancelIcon"
            size="16"
          />
          {{ $t('Cancel') }}
        </b-button>
        <b-button
          class="cancelBtn px-3 font-medium-1 font-weight-bolder"
          variant="success"
          :disabled="isSendingEmail"
          @click="onOk()"
        >
          <b-spinner
            v-if="isSendingEmail"
            small
          />
          <span v-else>{{ $t('Send email') }}</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>

// Configs
import { required } from '@/libs/validations/validations'
import { ValidationObserver } from 'vee-validate'
import { scrollToError } from '@core/utils/utils'
import jsonToFormData from '@/libs/jsonToFormData'

// Components
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import {
  BButton, BForm, BRow, BSpinner, BOverlay, BCol, BModal, BFormCheckbox, BFormTextarea, VBTooltip,
} from 'bootstrap-vue'
import store from '@/store'
import config from '../../verificationConfigs'
import AccountHolderInformation from './Tables/AccountHolderInformation.vue'
import VerificationResultsTables from './VerificationResultsTables.vue'
import VerificationRules from './VerificationRules.vue'
import InsurancePolicy from './InsurancePolicy.vue'
import FormBottom from './FormBottom.vue'

export default {
  name: 'VerificationForm',
  components: {
    BFormCheckbox,
    BFormTextarea,
    BModal,
    BCol,
    BRow,
    BForm,
    BButton,
    BOverlay,
    BSpinner,
    ErrorAlert,
    FormBottom,
    InsurancePolicy,
    VerificationRules,
    ValidationObserver,
    AccountHolderInformation,
    VerificationResultsTables,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      error: {},
      required,
      isSubmitting: false,
      isSubmit: false,
      isSendingEmail: false,
      isChecking: false,
      isQueryChecking: false,
      savedSuccessfully: false,
      checkedItems: {
        checkedIds: [],
        message: '',
      },
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    documentIdOptions() {
      return this.$store.state.listModule.documentIdList
    },
    verification() {
      return this.$store.state[this.MODULE_NAME].verificationForm
    },
    verificationFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].customerVerificationFormClone
    },
    isFormChanged() {
      console.log('JSON.stringify(this.verification)', JSON.stringify(this.verification))
      console.log('this.verificationFormClone', this.verificationFormClone)
      return JSON.stringify(this.verification) !== this.verificationFormClone
    },
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
  },
  methods: {
    // isChecked(id, listArray) {
    //   return Array.isArray(listArray) && listArray.includes(id)
    // },
    isEditAble() {
      this.isEdit = !this.isEdit
    },
    resetModalForm() {
      this.checkedItems.checkedIds = []
      this.checkedItems.message = ''
    },
    showModal(modalName) {
      this.$bvModal.show(modalName)
    },
    onOk() {
      this.isSendingEmail = true
      const checkedIds = Object.keys(this.checkedItems.checkedIds)
        .filter(key => this.checkedItems.checkedIds[key])
        .map(Number)
      const { message } = this.checkedItems

      const customerId = this.$route.params.id
      const data = {
        document_ids: checkedIds,
        customer: customerId,
        message,
      }
      this.sendNotification(this, data, `${this.MODULE_NAME}/requestDocument`, 'Successfully requested for documents')
        .then(() => {
          this.$emit('reload')
        })
        .catch(err => {
          this.error = err.response?.data
        }).finally(() => {
          this.isSendingEmail = false
          this.$bvModal.hide('show-request-documentation-modal')
        })
    },
    queryServiceCheck() {
      this.error = {}
      this.$refs.customerVerificationVal.validate()
        .then(success => {
          if (success) {
            this.isChecking = true
            this.isSubmitting = true
            this.isSubmit = true
            this.isQueryChecking = true
            const data = this.verification
            const filteredAttachments = data.attachments.filter(attachment => !attachment.id)
            const verificationData = {
              deposit_requirement: data.deposit_requirement,
              comments: data.comments,
              id: data.customer.id,
              status: data.status.id,
              duns_number: data.duns_number,
              insurance_policy: {
                policy_number: data.policy_number,
                attachments: filteredAttachments,
                coverage_start_date: data.coverage_start_date,
                coverage_end_date: data.coverage_end_date,
                insurance_company_name: data.insurance_company_name,
                amount_insured: data.amount_insured,
                deductible: data.deductible,
                allow_without_certificate: +data.allow_without_certificate,
                verified: +data.verified,
              },
            }

            if (verificationData.insurance_policy.coverage_start_date && verificationData.insurance_policy.coverage_end_date) {
              const startDate = new Date(verificationData.insurance_policy.coverage_start_date)
              const endDate = new Date(verificationData.insurance_policy.coverage_end_date)

              verificationData.insurance_policy.coverage_start_date = [startDate.getFullYear(), startDate.getMonth() + 1, startDate.getDate()].map(x => x.toString().padStart(2, '0')).join('-')
              verificationData.insurance_policy.coverage_end_date = [endDate.getFullYear(), endDate.getMonth() + 1, endDate.getDate()].map(x => x.toString().padStart(2, '0')).join('-')
            }

            const verificationFormData = jsonToFormData(verificationData)

            this.sendNotification(this, { id: data.customer.id, data: verificationFormData }, `${this.MODULE_NAME}/validateVerification`)
              .then(res => {
                this.$store.commit(`${this.MODULE_NAME_CUSTOMER}/SET_CUSTOMER_STATUS`, res.data.data.customer.customer_detail.status)
                this.savedSuccessfully = true
              })
              .catch(err => {
                this.error = err.response?.data
                this.isChecking = false
                this.isSubmitting = false
                this.isSubmit = false
                this.isQueryChecking = false
              }).finally(() => {
                if (this.savedSuccessfully) {
                  this.isChecking = true
                  this.isSubmitting = true
                  this.isSubmit = true
                  this.isQueryChecking = true
                  const customerId = this.$route.params?.id
                  this.sendNotification(this, { id: customerId }, `${this.MODULE_NAME}/queryServiceCheck`, 'The customer is successfully validated')
                    .then(res => {
                      const idScanObj = res.data.data.contact
                      const verificationRes = idScanObj.verification?.id_scan_response
                      this.$store.commit(`${this.MODULE_NAME}/SET_ID_SCAN_RESPONSE`, verificationRes)
                      this.$emit('reload')
                    }).catch(error => {
                      this.error = error.response?.data
                      this.isSubmitting = false
                    }).finally(() => {
                      this.isChecking = false
                      this.isSubmitting = false
                      this.isSubmit = false
                      this.isQueryChecking = false
                    })
                }
              })
          } else {
            this.savedSuccessfully = false
            scrollToError(this, this.$refs.customerVerificationVal)
          }
        })
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.isSubmit)
    },
    submit() {
      this.error = {}
      this.$refs.customerVerificationVal.validate()
        .then(success => {
          if (success) {
            this.isChecking = true
            this.isSubmitting = true
            this.isSubmit = true
            const data = this.verification
            const filteredAttachments = data.attachments.filter(attachment => !attachment.id)
            const verificationData = {
              deposit_requirement: data.deposit_requirement,
              comments: data.comments,
              id: data.customer.id,
              status: data.status.id,
              duns_number: data.duns_number,
              insurance_policy: {
                policy_number: data.policy_number,
                attachments: filteredAttachments,
                coverage_start_date: data.coverage_start_date,
                coverage_end_date: data.coverage_end_date,
                insurance_company_name: data.insurance_company_name,
                amount_insured: data.amount_insured,
                deductible: data.deductible,
                allow_without_certificate: +data.allow_without_certificate,
                verified: +data.verified,
              },
            }

            if (verificationData.insurance_policy.coverage_start_date && verificationData.insurance_policy.coverage_end_date) {
              const startDate = new Date(verificationData.insurance_policy.coverage_start_date)
              const endDate = new Date(verificationData.insurance_policy.coverage_end_date)

              verificationData.insurance_policy.coverage_start_date = [startDate.getFullYear(), startDate.getMonth() + 1, startDate.getDate()].map(x => x.toString().padStart(2, '0')).join('-')
              verificationData.insurance_policy.coverage_end_date = [endDate.getFullYear(), endDate.getMonth() + 1, endDate.getDate()].map(x => x.toString().padStart(2, '0')).join('-')
            }

            const verificationFormData = jsonToFormData(verificationData)

            this.sendNotification(this, { id: data.customer.id, data: verificationFormData }, `${this.MODULE_NAME}/validateVerification`)
              .then(res => {
                this.$router.push({
                  name: 'home-customers-financial-info',
                  params: { id: data.customer.id },
                })
                // this.$emit('reload')
                this.isSubmitting = false
                this.$store.commit(`${this.MODULE_NAME_CUSTOMER}/SET_CUSTOMER_STATUS`, res.data.data.customer.customer_detail.status)
                this.savedSuccessfully = true
              })
              .catch(err => {
                this.error = err.response?.data
                this.isSubmitting = false
              }).finally(() => {
                this.isChecking = false
              })
          } else {
            this.savedSuccessfully = false
            scrollToError(this, this.$refs.customerVerificationVal)
          }
        })
    },
    clear() {
      this.$refs.customerVerificationVal.reset()
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, {})
    },
    cancel() {
      // this.$refs.customerVerificationVal.reset()
      const smth = JSON.parse(this.verificationFormClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, smth)
    },
    // loader() {
    //   this.$refs.customerVerificationVal.reset()
    //   const smth = JSON.parse(this.verificationFormClone)
    //   this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, smth)
    // },
    getProps(fieldName) {
      return {
        is: this.fields[fieldName].type,
        field: this.fields[fieldName],
        name: fieldName,
      }
    },
  },
  setup() {
    const MODULE_NAME = 'verification'
    const MODULE_NAME_CLONE = 'cloneData'
    const MODULE_NAME_CUSTOMER = 'customers'

    const {
      fields, addressTableColumns, contactTableColumns, modals,
    } = config()
    return {
      modals,
      fields,
      MODULE_NAME,
      MODULE_NAME_CLONE,
      addressTableColumns,
      contactTableColumns,
      MODULE_NAME_CUSTOMER,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.modal-bg-color {
  background-color: #F3F3F3;
}
.verification-form {
  .checkboxLabel {
    margin-bottom: 0 !important;
  }
}

  .verification-checkbox {
    & .row.checkboxGroup {
      padding-left: 0 !important;
      margin-bottom: 10px;
    }
  }
  .verification-comments textarea {
    height: 160px;
  }
</style>
