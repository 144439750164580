<template>
  <!--  ID Scan.Net-->
  <div>
    <div
      class="d-flex align-items-center"
      style="gap: 10px; margin-bottom: 8px"
    >
      <feather-icon
        :icon="checkVerificationStatus()"
        size="16"
        :class="`${checkVerificationStatus() === 'LNoIcon' ? 'customer-status-icon':''}`"
      />
      <h5 class="font-weight-bolder mb-0">
        {{ $t('ID Scan IdentiFraud') }}
      </h5>
    </div>
    <div
      v-if="!verification.customer.verification || !IDScanIdentifier"
      class="d-flex justify-content-center mb-2 border align-items-center"
      style="border-radius: 5px; height: 36px"
    >
      <p
        class="d-flex align-items-center mb-0 text-warning"
        style="gap: 8px; opacity: 0.5"
      >
        <span>
          {{ $t('not validated yet') | capitalize }}
        </span>
      </p>
    </div>
    <b-card
      v-else
      :class="`${(IDScanIdentifier && IDScanIdentifier[IF_ID_SCAN_VALID_HAS_KEY_IDENTIFY]) ? 'adscan-card' : ''}`"
      no-body
    >
      <b-row v-if="(IDScanIdentifier && IDScanIdentifier[IF_ID_SCAN_VALID_HAS_KEY_IDENTIFY])">
        <b-col
          v-for="{ id, title, labelKeyArr, getFromThisObject, identiFraudChild, identiFraudChildSkipped } in [generalInformation]"
          :key="id"
          cols="6"
        >
          <div
            v-if="id === 'general-information'"
            class=" mb-2"
          >
            <h6 class="font-weight-bolder mb-2">
              {{ $t(title) }}
            </h6>
            <div
              v-for="{ label, key } in labelKeyArr"
              :key="key"
              class="px-1"
            >
              <div v-if="key === 'verificationResult'">
                <b-row
                  v-for="num in (IDScanIdentifier[getFromThisObject][0][key]['scores']).length"
                  :key="num"
                >
                  <template v-if="['FirstName', 'FamilyName', 'FullName'].includes(IDScanIdentifier[getFromThisObject][0][key]['scores'][num-1]['fieldName'])">
                    <b-col cols="6">
                      <b>{{ checkForName(IDScanIdentifier[getFromThisObject][0][key]['scores'][num-1]['fieldName']) }}</b>
                    </b-col>
                    <b-col cols="6">
                      <div>
                        <span>{{ IDScanIdentifier[getFromThisObject][0][key]['scores'][num-1]['score'] ? IDScanIdentifier[getFromThisObject][0][key]['scores'][num-1]['score'] : '---' | capitalize }}</span>
                      </div>
                    </b-col>
                  </template>
                </b-row>
              </div>
            </div>
            <div
              v-for="{ label, keyName, notFoundMessage } in identiFraudChild"
              :key="keyName"
              class="px-1"
            >
              <b-row v-if="IDScanIdentifier[getFromThisObject][0]['verificationResult']">
                <b-col cols="6">
                  <b>{{ label }}</b>
                </b-col>
                <b-col
                  v-if="IDScanIdentifier[getFromThisObject][0]['verificationResult']['data']"
                  cols="6"
                >
                  {{ IDScanIdentifier[getFromThisObject][0]['verificationResult']['data'][keyName] ? IDScanIdentifier[getFromThisObject][0]['verificationResult']['data'][keyName]['message'] : notFoundMessage }}
                </b-col>
              </b-row>
            </div>
            <div
              v-for="{ key } in labelKeyArr"
              :key="key"
              class="px-1"
            >
              <b-row v-if="IDScanIdentifier[getFromThisObject][0][key]['data']">
                <b-col cols="6">
                  <b>{{ $t('Phone Number') }}</b>
                </b-col>
                <b-col cols="6">
                  <span>{{ returnMessage(IDScanIdentifier[getFromThisObject][0][key]['data']['phoneVerificationResult']) }}</span>
                  <span>{{ returnMessage(IDScanIdentifier[getFromThisObject][0][key]['data']['phoneHighRiskResult']) }}</span>
                  <div v-if="IDScanIdentifier[getFromThisObject][0][key]['data']['phoneVerificationDetail']">
                    <span>{{ IDScanIdentifier[getFromThisObject][0][key]['data']['phoneVerificationDetail']['callerName'] + '. ' }}</span>
                    <span>{{ returnMessage(IDScanIdentifier[getFromThisObject][0][key]['data']['phoneVerificationDetail']['phoneType']) }}</span>
                    <div>
                      <span>{{ IDScanIdentifier[getFromThisObject][0][key]['data']['phoneVerificationDetail']['carrierName'] + '.  ' }}</span>
                      <span>{{ IDScanIdentifier[getFromThisObject][0][key]['data']['phoneVerificationDetail']['verificationCode'] }}</span>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div
              v-for="{ label, keyName, notFoundMessage, titleOfFraud } in identiFraudChildSkipped"
              :key="keyName"
              class="px-1"
            >
              <h6
                v-if="titleOfFraud"
                class="font-weight-bolder my-2"
                style="margin-left: -15px"
              >
                {{ $t(titleOfFraud) }}
              </h6>
              <b-row v-if="IDScanIdentifier[getFromThisObject][0]['verificationResult']">
                <b-col cols="6">
                  <b>{{ label }}</b>
                </b-col>
                <b-col
                  v-if="IDScanIdentifier[getFromThisObject][0]['verificationResult']['data']"
                  cols="6"
                >
                  {{ IDScanIdentifier[getFromThisObject][0]['verificationResult']['data'][keyName] ? IDScanIdentifier[getFromThisObject][0]['verificationResult']['data'][keyName]['message'] : notFoundMessage }}
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
        <b-col
          v-for="{ id, title, getFromThisObject, getParentObject, scoredData, dataStore } in [mixedDataScore]"
          :key="id"
          cols="6"
        >
          <div
            v-if="id === 'general-information-mixed'"
            class=" mb-2"
          >
            <h6 class="font-weight-bolder mb-2">
              {{ $t(title) }}
            </h6>
            <div
              v-for="{ label, fieldName } in scoredData"
              :key="fieldName"
              class="px-1"
            >
              <b-row
                v-for="num in (IDScanIdentifier[getFromThisObject][0][getParentObject]['scores']).length"
                :key="num"
              >
                <template v-if="IDScanIdentifier[getFromThisObject][0][getParentObject]['scores'][num-1]['fieldName'] === fieldName">
                  <b-col cols="6">
                    <b>{{ label }}</b>
                  </b-col>
                  <b-col cols="6">
                    <div>
                      <span>{{ IDScanIdentifier[getFromThisObject][0][getParentObject]['scores'][num-1]['score'] ? IDScanIdentifier[getFromThisObject][0][getParentObject]['scores'][num-1]['score'] : '---' | capitalize }}</span>
                    </div>
                  </b-col>
                </template>
              </b-row>
            </div>
            <div
              v-for="{ label, key } in dataStore"
              :key="key"
              class="px-1"
            >
              <b-row>
                <b-col cols="6">
                  <b>{{ label }}</b>
                </b-col>
                <b-col cols="6">
                  {{ IDScanIdentifier[getFromThisObject][0][getParentObject]['data'][key].message ? IDScanIdentifier[getFromThisObject][0][getParentObject]['data'][key].message : '---' | capitalize }}
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
      <div v-if="(IDScanIdentifier && IDScanIdentifier[INVALID_DATA_ERRORS])">
        <div
          class="d-flex justify-content-center mb-2 border align-items-center"
          style="border-radius: 5px; height: 36px"
        >
          <p
            class="d-flex align-items-center mb-0 text-danger"
            style="gap: 8px; opacity: 0.8"
          >
            <span
              v-for="({ message }, idx) in IDScanIdentifier[INVALID_DATA_ERRORS]"
              :key="idx"
            >
              {{ message | capitalize }}
              <span v-if="idx !== IDScanIdentifier[INVALID_DATA_ERRORS].length - 1">,</span>
            </span>
          </p>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import tableConfig from './tableIdentifraundConfig'

export default {
  name: 'IDScanTableIdentifraud',
  components: {
    BCard,
    BRow,
    BCol,
  },
  computed: {
    IDScanIdentifier() {
      const objIdentifier = this.$store.state[this.MODULE_NAME].idScanResponse
      return objIdentifier.identifraud
    },
    verification() {
      return this.$store.state[this.MODULE_NAME].verification
    },
  },
  methods: {
    returnMessage(value) {
      return (` ${value.message}` ?? '')
    },
    checkForName(fieldName) {
      switch (fieldName) {
        case 'FirstName':
          return 'First Name Score'
        case 'FamilyName':
          return 'Last Name Score'
        case 'FullName':
          return 'Full Name Score'
        default:
          return ''
      }
    },
    checkVerificationStatus() {
      const status = +this.IDScanIdentifier?.success ?? false

      if (this.IDScanIdentifier && this.IDScanIdentifier[this.INVALID_DATA_ERRORS]?.length) {
        return 'LNoIcon'
      }
      // eslint-disable-next-line no-nested-ternary
      return status ? 'LTickIcon' : 'LWarningIcon'
    },
    splitReason(reason) {
      if (reason) {
        const splittedReason = reason.split(';')
        return splittedReason[0]
      }
      return '---'
    },
  },
  setup() {
    const MODULE_NAME = 'verification'
    const {
      generalInformation, mixedDataScore, addressInID, IF_ID_SCAN_VALID_HAS_KEY_IDENTIFY, INVALID_DATA_ERRORS,
    } = tableConfig()

    return {
      MODULE_NAME,
      addressInID,
      mixedDataScore,
      INVALID_DATA_ERRORS,
      generalInformation,
      IF_ID_SCAN_VALID_HAS_KEY_IDENTIFY,
    }
  },
}
</script>
<style lang="scss">
.adscan-card{
  padding: 12px 10px;
  border: 1px solid #DDE3E7;
  border-radius: 5px;
  min-height: 480px;
}

.custom-style-table-idscan {
  & tbody tr td:first-child {
    padding-left: 23px !important;
  }
}
</style>
