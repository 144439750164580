export default function tableConfig() {
  const IF_ID_SCAN_VALID_HAS_KEY = 'document'
  const INVALID_DATA_ERRORS = 'invalidDataErrors'

  const personalInformation = {
    id: 'personal-information',
    title: 'Personal information',
    getFromThisObject: 'document',
    labelKeyArr: [
      {
        label: 'First Name',
        key: 'privateName',
      },
      {
        label: 'Last Name',
        key: 'familyName',
      },
      {
        label: 'Birthdate',
        key: 'dob',
      },
      {
        label: 'Gender',
        key: 'gender',
      },
      {
        label: 'Height',
        key: 'height',
      },
      {
        label: 'Weight',
        key: 'weight',
      },
      {
        label: 'Eyes',
        key: 'eyes',
      },
      {
        label: 'Hair',
        key: 'hair',
      },
    ],
  }
  const validationStatus = {
    id: 'validation-status',
    title: 'Validation Status',
    getFromThisObject: 'validationStatus',
    getFromExtraThisObject: 'documentValidationTests',
    labelKeyArr: [
      {
        label: 'Document Valid',
        key: 'documentIsValid',
      },
      {
        label: 'Expired',
        key: 'expired',
      },
    ],
  }
  const IDInformation = {
    id: 'id-information',
    title: 'ID Information',
    getFromThisObject: 'document',
    labelKeyArr: [
      {
        label: 'ID number',
        key: 'id',
      },
      {
        label: 'ID Type',
        key: 'idType',
      },
      {
        label: 'Issued On',
        key: 'issued',
      },
      {
        label: 'Expires',
        key: 'expires',
      },
      {
        label: 'Issued By',
        key: 'issuedBy',
      },

    ],
  }
  const addressInID = {
    id: 'addressInID',
    title: 'Address in ID',
    getFromThisObject: 'document',
    labelKeyArr: [
      {
        label: 'Address',
        key: 'address',
      },
      {
        label: 'City',
        key: 'city',
      },
      {
        label: 'State',
        key: 'state',
      },
      {
        label: 'ZIP',
        key: 'zip',
      },
      {
        label: 'Country',
        key: 'country',
      },
    ],
  }

  return {
    addressInID,
    IDInformation,
    validationStatus,
    personalInformation,
    INVALID_DATA_ERRORS,
    IF_ID_SCAN_VALID_HAS_KEY,
  }
}
