<template>
  <div>
    <h5 class="font-weight-bolder">
      {{ $t('TransUnion TLOxp') }}
    </h5>
    <b-table
      class="mb-1"
      :fields="TransUnionTLOxpTableColumns"
      :items="[transUnionTLOxp]"
      @row-clicked="rowClickedHandler"
    >
      <template #cell(risk)="{ data }">
        {{ '---' }}
      </template>
      <template #cell(reason)="{ data }">
        {{ '---' }}
      </template>
      <template #cell(status)="{ data }">
        {{ '---' }}
      </template>
      <template #cell(email_exists)="{ data }">
        {{ '---' }}
      </template>
      <template #cell(domain_exists)="{ data }">
        {{ '---' }}
      </template>
      <template #cell(domain_relevant_info)="{ data }">
        {{ '---' }}
      </template>
    </b-table>
  </div>
</template>

<script>

import { BTable } from 'bootstrap-vue'

import config from '@/views/main/customers/view/verification/verificationConfigs'

export default {
  name: 'TransUnionTLOxpTable',
  components: {
    BTable,
  },
  computed: {
    transUnionTLOxp() {
      return {
        tbd: 'TBD',
      }
    },
  },
  methods: {
    rowClickedHandler(rowData) {
      this.contactTableRowData = rowData
      this.showModal('customer-verification-contact-info_modal')
    },
  },
  setup() {
    const MODULE_NAME = 'verification'

    const { TransUnionTLOxpTableColumns } = config()
    return {
      MODULE_NAME,
      TransUnionTLOxpTableColumns,
    }
  },
}
</script>
