var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h5',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t('TransUnion TLOxp'))+" ")]),_c('b-table',{staticClass:"mb-1",attrs:{"fields":_vm.TransUnionTLOxpTableColumns,"items":[_vm.transUnionTLOxp]},on:{"row-clicked":_vm.rowClickedHandler},scopedSlots:_vm._u([{key:"cell(risk)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s('---')+" ")]}},{key:"cell(reason)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s('---')+" ")]}},{key:"cell(status)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s('---')+" ")]}},{key:"cell(email_exists)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s('---')+" ")]}},{key:"cell(domain_exists)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s('---')+" ")]}},{key:"cell(domain_relevant_info)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s('---')+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }