<template>
  <div>
    <h4 class="font-weight-bolder mb-1">
      {{ $t('Verification Rules') }}
    </h4>
    <b-row class="mb-1">
      <b-col
        v-for="field in ['deposit_requirement', 'duns_number']"
        :key="field"
        md="6"
      >
        <div
          style="height: 70px"
        >
          <span class="inputLabel">{{ fields[field].label }}</span>
          <b-skeleton
            height="38px"
            style="border-radius: 5px"
          />
        </div>
      </b-col>
    </b-row>
    <h4 class="font-weight-bolder mb-1">
      {{ $t('Insurance Policy') }}
    </h4>
    <b-row>
      <b-col md="6">
        <div
          class="d-flex align-items-center justify-content-between"
          style="margin-bottom: 10px"
        >
          <span class="">{{ fields['allow_without_certificate'].label }}</span>
          <b-skeleton
            class="mb-0"
            height="18px"
            style="border-radius: 5px; margin-left: 4px; min-width: 18px !important;"
          />
        </div>
        <div
          v-for="field in ['policy_number', 'insurance_company_name', 'amount_insured', 'deductible']"
          :key="field"
          style="height: 70px"
        >
          <span class="inputLabel">{{ fields[field].label }}</span>
          <b-skeleton
            height="38px"
            style="border-radius: 5px"
          />
        </div>
      </b-col>
      <b-col md="6">
        <label class="pl-1 font-weight-bolder">{{ $t('Coverage') }}</label>
        <div
          v-for="field in ['coverage_start_date', 'coverage_end_date']"
          :key="field"
          style="height: 70px"
        >
          <b-skeleton
            height="38px"
            style="border-radius: 5px"
          />
        </div>
        <label class="pl-1 font-weight-bolder">{{ $t('Certificate of Insurance') }}</label>
        <div class="d-flex justify-content-end">
          <feather-icon
            style="min-width: 37px; min-height: 37px"
            icon="LAddButtonIcon"
            size="24"
            class="cursor-pointer feather-add-icon"
          />
        </div>
        <div>
          <label class="pl-1 font-weight-bolder">{{ $t('File Name') }}</label>

          <b-skeleton
            height="38px"
            style="border-radius: 5px"
          />
        </div>
        <div
          class="d-flex align-items-center justify-content-between"
          style="margin-bottom: 10px"
        >
          <span class="">{{ fields['verified'].label }}</span>
          <b-skeleton
            class="mb-0"
            height="18px"
            style="border-radius: 5px; margin-left: 4px; min-width: 18px !important;"
          />
        </div>
      </b-col>
    </b-row>
    <h4 class="font-weight-bolder mb-1">
      {{ $t('Account Holder Information') }}
    </h4>
    <div class="mb-1">
      <!--Contact Table-->
      <b-table
        class="mb-1"
        striped
        :fields="contactTableColumns"
        :items="[{}]"
      >
        <template #cell(name)="">
          <b-skeleton
            height="18px"
            style="border-radius: 5px; margin-bottom: 0"
          />
        </template>
        <template #cell(cell_no)="">
          <b-skeleton
            height="18px"
            style="border-radius: 5px; margin-bottom: 0"
          />
        </template>
        <template #cell(email)="">
          <b-skeleton
            height="18px"
            style="border-radius: 5px; margin-bottom: 0"
          />
        </template>
      </b-table>
      <!--Address Table-->
      <b-table
        class="mb-1"
        striped
        :fields="addressTableColumns"
        :items="[{}]"
      >
        <template #cell(street)="">
          <b-skeleton
            height="18px"
            style="border-radius: 5px; margin-bottom: 0"
          />
        </template>
        <template #cell(city)="">
          <b-skeleton
            height="18px"
            style="border-radius: 5px; margin-bottom: 0"
          />
        </template>
        <template #cell(state.name)="">
          <b-skeleton
            height="18px"
            style="border-radius: 5px; margin-bottom: 0"
          />
        </template>
        <template #cell(type)="">
          <b-skeleton
            height="18px"
            style="border-radius: 5px; margin-bottom: 0"
          />
        </template>
      </b-table>
      <!--   emailageTableColumns   -->
      <h5 class="font-weight-bolder pl-1">
        {{ $t('Emailage') }}
      </h5>
      <b-table
        class="mb-1"
        striped
        :fields="emailageTableColumns"
        :items="[{}]"
      >
        <template #cell(action)="">
          <b-skeleton
            height="18px"
            style="border-radius: 5px; margin-bottom: 0"
          />
        </template>
        <template #cell(risk)="">
          <b-skeleton
            height="18px"
            style="border-radius: 5px; margin-bottom: 0"
          />
        </template>
        <template #cell(reason)="">
          <b-skeleton
            height="18px"
            style="border-radius: 5px; margin-bottom: 0"
          />
        </template>
        <template #cell(status)="">
          <b-skeleton
            height="18px"
            style="border-radius: 5px; margin-bottom: 0"
          />
        </template>
        <template #cell(email_exists)="">
          <b-skeleton
            height="18px"
            style="border-radius: 5px; margin-bottom: 0"
          />
        </template>
        <template #cell(domain_exists)="">
          <b-skeleton
            height="18px"
            style="border-radius: 5px; margin-bottom: 0"
          />
        </template>
        <template #cell(domain_relevant_info)="">
          <b-skeleton
            height="18px"
            style="border-radius: 5px; margin-bottom: 0"
          />
        </template>
      </b-table>
      <!--      &lt;!&ndash;   TransUnionTLOxpTableColumns   &ndash;&gt;-->
      <!--      <h5 class="font-weight-bolder pl-1">-->
      <!--        {{ $t('TransUnion TLOxp') }}-->
      <!--      </h5>-->
      <!--      <b-table-->
      <!--        class="mb-1"-->
      <!--        striped-->
      <!--        :fields="TransUnionTLOxpTableColumns"-->
      <!--        :items="[{}]"-->
      <!--      >-->
      <!--        <template #cell(tbd)="">-->
      <!--          <b-skeleton-->
      <!--            height="18px"-->
      <!--            style="border-radius: 5px; margin-bottom: 0"-->
      <!--          />-->
      <!--        </template>-->
      <!--      </b-table>-->
      <!--      &lt;!&ndash;   TelesignTableColumns   &ndash;&gt;-->
      <!--      <h5 class="font-weight-bolder pl-1">-->
      <!--        {{ $t('Telesign') }}-->
      <!--      </h5>-->
      <!--      <b-table-->
      <!--        class="mb-1"-->
      <!--        striped-->
      <!--        :fields="TelesignTableColumns"-->
      <!--        :items="[{}]"-->
      <!--      >-->
      <!--        <template #cell(tbd)="">-->
      <!--          <b-skeleton-->
      <!--            height="18px"-->
      <!--            style="border-radius: 5px; margin-bottom: 0"-->
      <!--          />-->
      <!--        </template>-->
      <!--      </b-table>-->
      <!--   IDScanTableColumns   -->
      <h5 class="font-weight-bolder pl-1">
        {{ $t('ID Scan.Net') }}
      </h5>
      <b-card
        class="adscan-card"
        no-body
      >
        <b-row>
          <b-col
            v-for="item in ['Personal information', 'Address in ID', 'Validation Status', 'ID Information']"
            :key="item"
            cols="6"
            class="mb-2"
          >
            <h6 class="font-weight-bolder mb-2">
              {{ $t(item) }}
            </h6>
            <b-row
              v-for="item in Array(5)"
              :key="item"
              class="mb-1"
            >
              <b-col cols="6">
                <b-skeleton
                  height="18px"
                  style="border-radius: 5px; margin-bottom: 0"
                  width="50%"
                />
              </b-col>
              <b-col cols="6">
                <b-skeleton
                  height="18px"
                  style="border-radius: 5px; margin-bottom: 0"
                  width="70%"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
      <!--   USPSTableColumns   -->
      <h5 class="font-weight-bolder pl-1">
        {{ $t('USPS') }}
      </h5>
      <b-table
        class="mb-1"
        striped
        :fields="USPSTableColumns"
        :items="[{}]"
      >
        <template #cell(action)="">
          <b-skeleton
            height="18px"
            style="border-radius: 5px; margin-bottom: 0"
          />
        </template>
        <template #cell(firm_name)="">
          <b-skeleton
            height="18px"
            style="border-radius: 5px; margin-bottom: 0"
          />
        </template>
        <template #cell(address)="">
          <b-skeleton
            height="18px"
            style="border-radius: 5px; margin-bottom: 0"
          />
        </template>
        <template #cell(city)="">
          <b-skeleton
            height="18px"
            style="border-radius: 5px; margin-bottom: 0"
          />
        </template>
        <template #cell(state)="">
          <b-skeleton
            height="18px"
            style="border-radius: 5px; margin-bottom: 0"
          />
        </template>
        <template #cell(zip5)="">
          <b-skeleton
            height="18px"
            style="border-radius: 5px; margin-bottom: 0"
          />
        </template>
        <template #cell(zip4)="">
          <b-skeleton
            height="18px"
            style="border-radius: 5px; margin-bottom: 0"
          />
        </template>
        <template #cell(domain_relevant_info)="">
          <b-skeleton
            height="18px"
            style="border-radius: 5px; margin-bottom: 0"
          />
        </template>
      </b-table>
    </div>
    <b-row>
      <b-col md="6">
        <div
          style="height: 250px"
        >
          <span class="inputLabel">{{ fields['comments'].label }}</span>
          <div
            class="border d-flex flex-column"
            style="padding: 15px; border-radius: 5px"
          >
            <b-skeleton
              height="18px"
              width="50%"
              style="border-radius: 5px"
            />
            <b-skeleton
              height="18px"
              width="90%"
              style="border-radius: 5px"
            />
            <b-skeleton
              width="80%"
              height="18px"
              style="border-radius: 5px"
            />
            <b-skeleton
              width="85%"
              height="18px"
              style="border-radius: 5px"
            />
            <b-skeleton
              width="60%"
              height="18px"
              style="border-radius: 5px"
            />
            <b-skeleton
              height="18px"
              style="border-radius: 5px"
            />
            <b-skeleton
              height="18px"
              style="border-radius: 5px"
            />
          </div>
        </div>
      </b-col>
      <b-col md="6">
        <div
          style="height: 70px"
        >
          <span class="inputLabel">{{ fields['status'].label }}</span>
          <b-skeleton
            height="38px"
            style="border-radius: 5px"
          />
        </div>
      </b-col>
    </b-row>
  </div>

</template>
<script>
import {
  BRow, BCol, BSkeleton, BTable, BCard,
} from 'bootstrap-vue'
import config from '../verificationConfigs'

export default {
  name: 'Skeleton',
  components: {
    BCol,
    BRow,
    BCard,
    BTable,
    BSkeleton,
  },
  data() {
    return {
      verificationsList: [
        // {
        //   name: 'TransUnion TLOxp',
        // },
        {
          name: 'Telesign',
        },
        {
          name: 'ID Scan',
        },
        {
          name: 'Emailage',
        },
        {
          name: 'USPS',
        },
      ],
    }
  },
  setup() {
    const {
      fields, addressTableColumns, contactTableColumns, emailageTableColumns, TransUnionTLOxpTableColumns, TelesignTableColumns, IDScanTableColumns, USPSTableColumns,
    } = config()

    return {
      fields,
      USPSTableColumns,
      IDScanTableColumns,
      addressTableColumns,
      contactTableColumns,
      emailageTableColumns,
      TelesignTableColumns,
      TransUnionTLOxpTableColumns,
    }
  },
}
</script>
