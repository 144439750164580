<template>
  <div>
    <portal to="body-top">
      <customer-name />
    </portal>
    <div>
      <skeleton v-if="loading" />
      <verification-form
        v-if="!loading"
        ref="verification-form"
        @reload="load"
      />
    </div>
  </div>
</template>
<script>

import CustomerName from '../../components/CustomerName.vue'
import VerificationForm from './components/VerificationForm/VerificationForm.vue'
import Skeleton from './components/Skeleton.vue'

export default {
  name: 'Verification',
  components: {
    Skeleton,
    VerificationForm,
    CustomerName,
  },
  data() {
    return {
      loading: false,
      defaults: {},
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['verification-form']) this.$refs['verification-form'].showConfirmationModal(next)
  },
  computed: {
    verificationDefaultStatusList() {
      return this.$store.state.listModule.verificationDefaultStatus
    },
  },
  async created() {
    this.$emit('updateMenu', 'home-customers-verification')
    this.loading = true
    await this.$store.dispatch(`${this.MODULE_NAME}/getDefaults`)
      .then(res => {
        const { data } = res.data

        this.defaults = data
      })
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      this.$store.dispatch(`${this.MODULE_NAME}/getVerification`).then(res => {
        const { data } = res.data

        const insurancePolicy = data.insurance_policy ?? {}
        const customerVerification = data.customer.verification ?? {}
        // PLEASE DON'T DELETE
        if (insurancePolicy.coverage_start_date && insurancePolicy.coverage_end_date) {
          insurancePolicy.coverage_start_date = new Date(insurancePolicy.coverage_start_date)
          insurancePolicy.coverage_end_date = new Date(insurancePolicy.coverage_end_date)
        }

        const getCustomerNotVerifiedStatus = this.verificationDefaultStatusList.find(status => status.text === this.defaults.verification_default_status)

        const merge = {
          ...data,
          id: data.customer.id,
          duns_number: customerVerification.duns_number ?? data.duns_number,
          comments: customerVerification.comments,
          status: data.customer.customer_detail.status >= 0 ? this.verificationDefaultStatusList.find(verification => verification.id === data.customer.customer_detail.status) : getCustomerNotVerifiedStatus,
          deposit_requirement: customerVerification.deposit_requirement ? +customerVerification.deposit_requirement : +this.defaults.verification_first_rental,
          attachments: insurancePolicy.attachments ?? [],
          policy_number: insurancePolicy.policy_number,
          coverage_start_date: insurancePolicy.coverage_start_date * 1000,
          coverage_end_date: insurancePolicy.coverage_end_date * 1000,
          insurance_company_name: insurancePolicy.insurance_company_name,
          amount_insured: insurancePolicy.amount_insured ? +insurancePolicy.amount_insured : null,
          allow_without_certificate: insurancePolicy.allow_without_certificate ?? false,
          deductible: insurancePolicy.deductible ? +insurancePolicy.deductible : null,
          verified: insurancePolicy.verified ?? false,
          verification_default_status: this.defaults.verification_default_status,
        }

        const emailAgeResponse = data.contact ? data.contact.verification?.emailage_response ?? {} : {}
        const teleSignResponse = data.contact ? data.contact.verification?.telesign_response ?? {} : {}
        const idScanResponse = data.contact ? data.contact.verification?.id_scan_response ?? {} : {}
        const uspsResponse = data.address ? data.address.verification?.usps_response ?? {} : {}
        const transUnionTLOxp = {}

        this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, merge)
        this.$store.commit(`${this.MODULE_NAME}/SET_CONTACT_TABLE`, data.contact)
        this.$store.commit(`${this.MODULE_NAME}/SET_ADDRESS_TABLE`, data.address)
        this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_CUSTOMER_VERIFICATION_FORM_CLONE`, merge)
        this.$store.commit(`${this.MODULE_NAME_CUSTOMER}/SET_CUSTOMER_STATUS`, data.customer.customer_detail.status)

        this.$store.commit(`${this.MODULE_NAME}/SET_EMAIL_AGE_RESPONSE`, emailAgeResponse)
        this.$store.commit(`${this.MODULE_NAME}/SET_TELE_SIGN_RESPONSE`, teleSignResponse)
        this.$store.commit(`${this.MODULE_NAME}/SET_ID_SCAN_RESPONSE`, idScanResponse)
        this.$store.commit(`${this.MODULE_NAME}/SET_USPS_RESPONSE`, uspsResponse)
        this.$store.commit(`${this.MODULE_NAME}/SET_TRANS_UNION_TLOXP_RESPONSE`, transUnionTLOxp)
      }).catch(err => {
        this.error = true
        this.errorNotification(this, err)
      }).finally(() => {
        this.loading = false
      })
    },
  },
  setup() {
    const MODULE_NAME = 'verification'
    const MODULE_NAME_CLONE = 'cloneData'
    const MODULE_NAME_CUSTOMER = 'customers'

    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      MODULE_NAME_CUSTOMER,
    }
  },
}
</script>
