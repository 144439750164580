import {
  TEXT_INPUT, FLAT_PICKER, TEXTAREA_INPUT, L_CURRENCY_MASK_INPUT, L_TAG_INPUT, SELECT_INPUT, CHECKBOX_LEF,
} from '@/views/components/DynamicForm/constants'
import { ref } from '@vue/composition-api'

export default function config() {
  const ACCESS_ABILITY_VERIFICATION = { action: 'change', subject: 'Verification' }
  // Table Handlers
  const addressTableColumns = [
    { key: 'street', label: 'Address' },
    { key: 'city', label: 'City' },
    { key: 'state.name', label: 'State' },
    { key: 'type', label: 'Type' },
  ]

  const contactTableColumns = [
    {
      key: 'name',
      label: 'Account Holder',
    },
    {
      key: 'cell_no',
      label: 'Phone',
    },
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'type',
      label: 'Type',
    },
  ]

  const emailageTableColumns = [
    { key: 'action', label: '' },
    { key: 'risk', label: 'Fraud Risk' },
    { key: 'reason', label: 'Reason' },
    { key: 'status', label: 'Status' },
    { key: 'email_exists', label: 'Email Exists' },
    { key: 'domain_exists', label: 'Domain Exists' },
    { key: 'domain_relevant_info', label: 'Domain Relevant Info' },
  ]

  const USPSTableColumns = [
    { key: 'action', label: '' },
    { key: 'firm_name', label: 'FIRM NAME' },
    { key: 'address', label: 'ADDRESS2' },
    { key: 'city', label: 'CITY' },
    { key: 'state', label: 'STATE' },
    { key: 'zip5', label: 'ZIP5' },
    { key: 'zip4', label: 'ZIP4' },
    { key: 'domain_relevant_info', label: 'Domain Relevant Info' },
  ]

  const TransUnionTLOxpTableColumns = [
    { key: 'tbd', label: 'TBD' },
  ]
  const TelesignTableColumns = [
    { key: 'tbd', label: 'TBD' },
  ]

  const modals = [
    'customer-verification-contact-info_modal',
    'customer-verification-address-info_modal',
    'customer-verification-emailage_response-info_modal',
    'customer-verification-usps_response-info_modal',
    'customer-verification-id-scan-response-info_modal',
    'customer-verification-tele-sign-response-info_modal',
    'customer-verification-trans-union-tloxp-response-info_modal',
  ]
  const fields = ref({
    // Company Verification Fields
    duns_number: {
      type: TEXT_INPUT,
      label: 'DUNS Number',
      placeholder: 'Type...',
    },
    insurance_company_name: {
      type: L_TAG_INPUT,
      label: 'Insurance Company Name',
      store: 'insuranceCompanyNameList',
      rules: 'required',
    },
    amount_insured: {
      type: L_CURRENCY_MASK_INPUT,
      label: 'Amount Insured',
      placeholder: '$0.00',
      rules: 'required',
    },
    deductible: {
      type: L_CURRENCY_MASK_INPUT,
      label: 'Deductible',
      placeholder: '$0.00',
      rules: 'required',
    },
    policy_number: {
      type: TEXT_INPUT,
      label: 'Policy Number',
      rules: 'required',
    },
    attachments: {
      type: null,
      label: '',
    },
    coverage_start_date: {
      type: FLAT_PICKER,
      label: 'Start Date',
      rules: 'required',
      options: {
        config: {
          'is-calendar-icon': true,
        },
      },
    },
    coverage_end_date: {
      type: FLAT_PICKER,
      label: 'End Date',
      rules: 'required',
      options: {
        config: {
          'is-calendar-icon': true,
        },
      },
    },
    allow_without_certificate: {
      type: CHECKBOX_LEF,
      label: 'Does not have an insurance',
    },
    verified: {
      type: CHECKBOX_LEF,
      label: 'Certificate of Insurance Visually Verified',
    },

    //  Deposit Requirements Fields
    deposit_requirement: {
      type: L_CURRENCY_MASK_INPUT,
      label: 'Deposit required for rental',
      placeholder: '$0.00',
      rules: 'required',
    },
    comments: {
      type: TEXTAREA_INPUT,
      label: 'Comments',
      options: {
        placeholder: 'Write notes here...',
      },
    },
    status: {
      type: SELECT_INPUT,
      label: 'Rental Status',
      store: 'verificationDefaultStatus',
      rules: 'required',
      options: {
        label: 'text',
      },
    },
  })

  const CALENDAR_CONFIG = {
    static: true,
    mode: 'single',
  }

  const attachmentColumns = [
    'File Name', 'Date', 'Type', 'Category',
  ]

  const changeAbleInputFieldsOnRequiredRule = ['policy_number', 'insurance_company_name', 'amount_insured', 'deductible', 'coverage_start_date', 'coverage_end_date', 'verified', 'attachments']

  return {
    modals,
    fields,
    CALENDAR_CONFIG,
    USPSTableColumns,
    attachmentColumns,
    addressTableColumns,
    contactTableColumns,
    emailageTableColumns,
    TelesignTableColumns,
    TransUnionTLOxpTableColumns,
    changeAbleInputFieldsOnRequiredRule,
    ACCESS_ABILITY_VERIFICATION,
  }
}
