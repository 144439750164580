<template>
  <!--  ID Scan.Net-->
  <div>
    <div
      class="d-flex align-items-center"
      style="gap: 10px; margin-bottom: 8px"
    >
      <feather-icon
        :icon="checkVerificationStatus()"
        size="16"
        :class="`${checkVerificationStatus() === 'LNoIcon' ? 'customer-status-icon':''}`"
      />
      <h5 class="font-weight-bolder mb-0">
        {{ $t('ID Scan Criminal Record') }}
      </h5>
    </div>
    <div
      v-if="!verification.customer.verification || !IDScanCriminal"
      class="d-flex justify-content-center mb-2 border align-items-center"
      style="border-radius: 5px; height: 36px"
    >
      <p
        class="d-flex align-items-center mb-0 text-warning"
        style="gap: 8px; opacity: 0.5"
      >
        <span>
          {{ $t('not validated yet') | capitalize }}
        </span>
      </p>
    </div>
    <b-card
      v-else
      :class="`${(IDScanCriminal && IDScanCriminal[IF_ID_SCAN_VALID_HAS_KEY_CRIMINAL]) ? 'adscan-card-criminal' : ''}`"
      no-body
    >
      <b-row v-if="(IDScanCriminal && IDScanCriminal[IF_ID_SCAN_VALID_HAS_KEY_CRIMINAL][0][OFFENSE_OBJECT_NAME])">
        <b-col
          v-for="{ title, items } in [criminalInformation]"
          :key="title"
          cols="12"
        >
          <div
            v-for="num in (IDScanCriminal[IF_ID_SCAN_VALID_HAS_KEY_CRIMINAL][0][OFFENSE_OBJECT_NAME].length)"
            :key="num"
            class="mb-2"
          >
            <h6 class="font-weight-bolder mb-2">
              <span>{{ title }}</span>
              <span> — </span>
              <span>{{ IDScanCriminal[IF_ID_SCAN_VALID_HAS_KEY_CRIMINAL][0][OFFENSE_OBJECT_NAME][num-1] ? IDScanCriminal[IF_ID_SCAN_VALID_HAS_KEY_CRIMINAL][0][OFFENSE_OBJECT_NAME][num-1]['title'] : $t('Not Specified') }}</span>
            </h6>
            <div
              v-for="{ label, key } in items"
              :key="key"
              class="px-1"
            >
              <b-row v-if="key !== 'title'">
                <b-col cols="3">
                  <b>{{ label }}</b>
                </b-col>
                <b-col cols="9">
                  <div>
                    {{ IDScanCriminal[IF_ID_SCAN_VALID_HAS_KEY_CRIMINAL][0][OFFENSE_OBJECT_NAME][num-1][key] ? IDScanCriminal[IF_ID_SCAN_VALID_HAS_KEY_CRIMINAL][0][OFFENSE_OBJECT_NAME][num-1][key] : 'NO' }}
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
      <div v-if="(IDScanCriminal && IDScanCriminal[INVALID_DATA_ERRORS])">
        <div
          class="d-flex justify-content-center mb-2 border align-items-center"
          style="border-radius: 5px; height: 36px"
        >
          <p
            class="d-flex align-items-center mb-0 text-danger"
            style="gap: 8px; opacity: 0.8"
          >
            <span
              v-for="({ message }, idx) in IDScanCriminal[INVALID_DATA_ERRORS]"
              :key="idx"
            >
              {{ message | capitalize }}
              <span v-if="idx !== IDScanCriminal[INVALID_DATA_ERRORS].length - 1">,</span>
            </span>
          </p>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import tableConfig from './tableCriminalConfig'

export default {
  name: 'IDScanTableCriminal',
  components: {
    BCard,
    BRow,
    BCol,
  },
  computed: {
    IDScanCriminal() {
      const objCriminal = this.$store.state[this.MODULE_NAME].idScanResponse
      return objCriminal.criminal
    },
    verification() {
      return this.$store.state[this.MODULE_NAME].verification
    },
  },
  methods: {
    checkVerificationStatus() {
      const status = +this.IDScanCriminal?.success ?? false

      if (this.IDScanCriminal && this.IDScanCriminal[this.INVALID_DATA_ERRORS]?.length) {
        return 'LNoIcon'
      }
      // eslint-disable-next-line no-nested-ternary
      return status ? 'LTickIcon' : 'LWarningIcon'
    },
    // splitReason(reason) {
    //   if (reason) {
    //     const splittedReason = reason.split(';')
    //     console.log(splittedReason)
    //     return splittedReason[0]
    //   }
    //   return '---'
    // },
  },
  setup() {
    const MODULE_NAME = 'verification'
    const {
      criminalInformation, OFFENSE_OBJECT_NAME, IF_ID_SCAN_VALID_HAS_KEY_CRIMINAL, INVALID_DATA_ERRORS,
    } = tableConfig()

    return {
      criminalInformation,
      INVALID_DATA_ERRORS,
      MODULE_NAME,
      OFFENSE_OBJECT_NAME,
      IF_ID_SCAN_VALID_HAS_KEY_CRIMINAL,
    }
  },
}
</script>
<style lang="scss">
.adscan-card-criminal{
  padding: 12px 10px;
  border: 1px solid #DDE3E7;
  border-radius: 5px;
  min-height: 250px;
}

.custom-style-table-idscan {
  & tbody tr td:first-child {
    padding-left: 23px !important;
  }
}
</style>
