<template>
  <div>
    <h5 class="font-weight-bolder">
      {{ $t('Emailage') }}
    </h5>
    <b-table
      class="mb-1 table--custom-style"
      :fields="emailageTableColumns"
      :items="[emailage]"
    >
      <template #cell(action)="">
        <div
          class="d-flex justify-content-center"
        >
          <Can
            :i="ACCESS_ABILITY_VERIFICATION.action"
            :a="ACCESS_ABILITY_VERIFICATION.subject"
          >
            <feather-icon
              icon="LEyeIcon"
              size="24"
              class="cursor-pointer border-dotted"
              style="width: 32px; height: 26px; padding: 5px"
              @click="showModal()"
            />
          </Can>
        </div>
      </template>
      <template #cell(risk)="data">
        {{ data.item.fraudRisk ? data.item.fraudRisk : '---' }}
      </template>
      <template #cell(reason)="data">
        {{ data.item.EAReason ? data.item.EAReason : '---' }}
      </template>
      <template #cell(status)="data">
        {{ data.item.status ? data.item.status: '---' }}
      </template>
      <template #cell(email_exists)="data">
        {{ data.item.emailExists ? data.item.emailExists : '---' }}
      </template>
      <template #cell(domain_exists)="data">
        {{ data.item.domainExists ? data.item.domainExists : '---' }}
      </template>
      <template #cell(domain_relevant_info)="data">
        {{ data.item.domainrelevantinfo ? data.item.domainrelevantinfo : '---' }}
      </template>
    </b-table>
    <b-modal
      id="customer-verification-emailage_response-info_modal"
      centered
      hide-header
      body-class="customer-verification-emailage_response-info_modal-body px-2 py-2"
      footer-class="customer-verification-emailage_response-info_modal-footer"
      ok-variant="success"
      ok-title="Hide"
      ok-only
      size="xl"
      modal-class="customer-verification-emailage_response-info_modal"
    >
      <h3 class="text-center font-weight-bolder mb-2">
        Emailage
      </h3>
      <div class="d-flex flex-wrap">
        <div
          v-for="([key, val], idx) of Object.entries(emailage)"
          :key="idx"
          class="d-flex"
          style="width: 33.3333%"
        >
          <h5 class="d-flex flex-column">
            <b style="text-transform: uppercase; margin-right: 5px">{{ key }}:</b>
          </h5>
          <h5>{{ val ? val : 'No' }}</h5>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>

import { BTable } from 'bootstrap-vue'

import config from '@/views/main/customers/view/verification/verificationConfigs'

export default {
  name: 'EmailageTable',
  components: {
    BTable,
  },
  computed: {
    emailage() {
      return this.$store.state[this.MODULE_NAME].emailAgeResponse
    },
  },
  methods: {
    rowClickedHandler(rowData) {
      this.contactTableRowData = rowData
      this.showModal('customer-verification-contact-info_modal')
    },
    showModal() {
      this.$bvModal.show('customer-verification-emailage_response-info_modal')
    },
  },
  setup() {
    const MODULE_NAME = 'verification'

    const { emailageTableColumns, ACCESS_ABILITY_VERIFICATION } = config()
    return {
      MODULE_NAME,
      emailageTableColumns,
      ACCESS_ABILITY_VERIFICATION,
    }
  },
}
</script>
<style lang="scss">
.table--custom-style {
  & thead tr th  {
    padding: 0 5px !important;
  }
  & tbody tr td  {
    padding: 9.5px 5px !important;
  }
}
</style>
