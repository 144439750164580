var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"10px","margin-bottom":"8px"}},[_c('feather-icon',{class:("" + (_vm.checkVerificationStatus() === 'LNoIcon' ? 'customer-status-icon':'')),attrs:{"icon":_vm.checkVerificationStatus(),"size":"16"}}),_c('h5',{staticClass:"font-weight-bolder mb-0"},[_vm._v(" "+_vm._s(_vm.$t('ID Scan IdentiFraud'))+" ")])],1),(!_vm.verification.customer.verification || !_vm.IDScanIdentifier)?_c('div',{staticClass:"d-flex justify-content-center mb-2 border align-items-center",staticStyle:{"border-radius":"5px","height":"36px"}},[_c('p',{staticClass:"d-flex align-items-center mb-0 text-warning",staticStyle:{"gap":"8px","opacity":"0.5"}},[_c('span',[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t('not validated yet')))+" ")])])]):_c('b-card',{class:("" + ((_vm.IDScanIdentifier && _vm.IDScanIdentifier[_vm.IF_ID_SCAN_VALID_HAS_KEY_IDENTIFY]) ? 'adscan-card' : '')),attrs:{"no-body":""}},[((_vm.IDScanIdentifier && _vm.IDScanIdentifier[_vm.IF_ID_SCAN_VALID_HAS_KEY_IDENTIFY]))?_c('b-row',[_vm._l(([_vm.generalInformation]),function(ref){
var id = ref.id;
var title = ref.title;
var labelKeyArr = ref.labelKeyArr;
var getFromThisObject = ref.getFromThisObject;
var identiFraudChild = ref.identiFraudChild;
var identiFraudChildSkipped = ref.identiFraudChildSkipped;
return _c('b-col',{key:id,attrs:{"cols":"6"}},[(id === 'general-information')?_c('div',{staticClass:" mb-2"},[_c('h6',{staticClass:"font-weight-bolder mb-2"},[_vm._v(" "+_vm._s(_vm.$t(title))+" ")]),_vm._l((labelKeyArr),function(ref){
var label = ref.label;
var key = ref.key;
return _c('div',{key:key,staticClass:"px-1"},[(key === 'verificationResult')?_c('div',_vm._l(((_vm.IDScanIdentifier[getFromThisObject][0][key]['scores']).length),function(num){return _c('b-row',{key:num},[(['FirstName', 'FamilyName', 'FullName'].includes(_vm.IDScanIdentifier[getFromThisObject][0][key]['scores'][num-1]['fieldName']))?[_c('b-col',{attrs:{"cols":"6"}},[_c('b',[_vm._v(_vm._s(_vm.checkForName(_vm.IDScanIdentifier[getFromThisObject][0][key]['scores'][num-1]['fieldName'])))])]),_c('b-col',{attrs:{"cols":"6"}},[_c('div',[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.IDScanIdentifier[getFromThisObject][0][key]['scores'][num-1]['score'] ? _vm.IDScanIdentifier[getFromThisObject][0][key]['scores'][num-1]['score'] : '---')))])])])]:_vm._e()],2)}),1):_vm._e()])}),_vm._l((identiFraudChild),function(ref){
var label = ref.label;
var keyName = ref.keyName;
var notFoundMessage = ref.notFoundMessage;
return _c('div',{key:keyName,staticClass:"px-1"},[(_vm.IDScanIdentifier[getFromThisObject][0]['verificationResult'])?_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b',[_vm._v(_vm._s(label))])]),(_vm.IDScanIdentifier[getFromThisObject][0]['verificationResult']['data'])?_c('b-col',{attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.IDScanIdentifier[getFromThisObject][0]['verificationResult']['data'][keyName] ? _vm.IDScanIdentifier[getFromThisObject][0]['verificationResult']['data'][keyName]['message'] : notFoundMessage)+" ")]):_vm._e()],1):_vm._e()],1)}),_vm._l((labelKeyArr),function(ref){
var key = ref.key;
return _c('div',{key:key,staticClass:"px-1"},[(_vm.IDScanIdentifier[getFromThisObject][0][key]['data'])?_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b',[_vm._v(_vm._s(_vm.$t('Phone Number')))])]),_c('b-col',{attrs:{"cols":"6"}},[_c('span',[_vm._v(_vm._s(_vm.returnMessage(_vm.IDScanIdentifier[getFromThisObject][0][key]['data']['phoneVerificationResult'])))]),_c('span',[_vm._v(_vm._s(_vm.returnMessage(_vm.IDScanIdentifier[getFromThisObject][0][key]['data']['phoneHighRiskResult'])))]),(_vm.IDScanIdentifier[getFromThisObject][0][key]['data']['phoneVerificationDetail'])?_c('div',[_c('span',[_vm._v(_vm._s(_vm.IDScanIdentifier[getFromThisObject][0][key]['data']['phoneVerificationDetail']['callerName'] + '. '))]),_c('span',[_vm._v(_vm._s(_vm.returnMessage(_vm.IDScanIdentifier[getFromThisObject][0][key]['data']['phoneVerificationDetail']['phoneType'])))]),_c('div',[_c('span',[_vm._v(_vm._s(_vm.IDScanIdentifier[getFromThisObject][0][key]['data']['phoneVerificationDetail']['carrierName'] + '. '))]),_c('span',[_vm._v(_vm._s(_vm.IDScanIdentifier[getFromThisObject][0][key]['data']['phoneVerificationDetail']['verificationCode']))])])]):_vm._e()])],1):_vm._e()],1)}),_vm._l((identiFraudChildSkipped),function(ref){
var label = ref.label;
var keyName = ref.keyName;
var notFoundMessage = ref.notFoundMessage;
var titleOfFraud = ref.titleOfFraud;
return _c('div',{key:keyName,staticClass:"px-1"},[(titleOfFraud)?_c('h6',{staticClass:"font-weight-bolder my-2",staticStyle:{"margin-left":"-15px"}},[_vm._v(" "+_vm._s(_vm.$t(titleOfFraud))+" ")]):_vm._e(),(_vm.IDScanIdentifier[getFromThisObject][0]['verificationResult'])?_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b',[_vm._v(_vm._s(label))])]),(_vm.IDScanIdentifier[getFromThisObject][0]['verificationResult']['data'])?_c('b-col',{attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.IDScanIdentifier[getFromThisObject][0]['verificationResult']['data'][keyName] ? _vm.IDScanIdentifier[getFromThisObject][0]['verificationResult']['data'][keyName]['message'] : notFoundMessage)+" ")]):_vm._e()],1):_vm._e()],1)})],2):_vm._e()])}),_vm._l(([_vm.mixedDataScore]),function(ref){
var id = ref.id;
var title = ref.title;
var getFromThisObject = ref.getFromThisObject;
var getParentObject = ref.getParentObject;
var scoredData = ref.scoredData;
var dataStore = ref.dataStore;
return _c('b-col',{key:id,attrs:{"cols":"6"}},[(id === 'general-information-mixed')?_c('div',{staticClass:" mb-2"},[_c('h6',{staticClass:"font-weight-bolder mb-2"},[_vm._v(" "+_vm._s(_vm.$t(title))+" ")]),_vm._l((scoredData),function(ref){
var label = ref.label;
var fieldName = ref.fieldName;
return _c('div',{key:fieldName,staticClass:"px-1"},_vm._l(((_vm.IDScanIdentifier[getFromThisObject][0][getParentObject]['scores']).length),function(num){return _c('b-row',{key:num},[(_vm.IDScanIdentifier[getFromThisObject][0][getParentObject]['scores'][num-1]['fieldName'] === fieldName)?[_c('b-col',{attrs:{"cols":"6"}},[_c('b',[_vm._v(_vm._s(label))])]),_c('b-col',{attrs:{"cols":"6"}},[_c('div',[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.IDScanIdentifier[getFromThisObject][0][getParentObject]['scores'][num-1]['score'] ? _vm.IDScanIdentifier[getFromThisObject][0][getParentObject]['scores'][num-1]['score'] : '---')))])])])]:_vm._e()],2)}),1)}),_vm._l((dataStore),function(ref){
var label = ref.label;
var key = ref.key;
return _c('div',{key:key,staticClass:"px-1"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b',[_vm._v(_vm._s(label))])]),_c('b-col',{attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.IDScanIdentifier[getFromThisObject][0][getParentObject]['data'][key].message ? _vm.IDScanIdentifier[getFromThisObject][0][getParentObject]['data'][key].message : '---'))+" ")])],1)],1)})],2):_vm._e()])})],2):_vm._e(),((_vm.IDScanIdentifier && _vm.IDScanIdentifier[_vm.INVALID_DATA_ERRORS]))?_c('div',[_c('div',{staticClass:"d-flex justify-content-center mb-2 border align-items-center",staticStyle:{"border-radius":"5px","height":"36px"}},[_c('p',{staticClass:"d-flex align-items-center mb-0 text-danger",staticStyle:{"gap":"8px","opacity":"0.8"}},_vm._l((_vm.IDScanIdentifier[_vm.INVALID_DATA_ERRORS]),function(ref,idx){
var message = ref.message;
return _c('span',{key:idx},[_vm._v(" "+_vm._s(_vm._f("capitalize")(message))+" "),(idx !== _vm.IDScanIdentifier[_vm.INVALID_DATA_ERRORS].length - 1)?_c('span',[_vm._v(",")]):_vm._e()])}),0)])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }