<template>
  <div class="w-100 ml-1 mr-1">
    <h4 class="font-weight-bolder">
      <feather-icon
        :icon="verificationVerified ? 'LTickIcon': 'LWarningIcon'"
        size="20"
      />
      <span>
        {{ $t('Account Holder Information') }}
      </span>
    </h4>
    <!--Contact Table-->
    <b-table
      class="mb-1"
      striped
      :fields="contactTableColumns"
      :items="[verificationContact]"
      @row-clicked="contactTableRowClickHandler"
    >
      <template #cell(name)="data">
        {{ `${data.item.first_name ? data.item.first_name : '---'}` }}
        {{ `${data.item.middle_name ? data.item.middle_name: '---'}` }}
        {{ `${data.item.last_name ? data.item.last_name: '---'}` }}
      </template>
      <template #cell(cell_no)="data">
        {{ `${data.item.cell_no ? getPhoneNumber(data.item) : '---'}` }}
      </template>
      <template #cell(email)="data">
        {{ `${data.item.email ? data.item.email : '---'}` }}
      </template>
      <template #cell(type)="data">
        {{ data ? getTypeTextContact(data) : '---' }}
      </template>
    </b-table>

    <!--Address Table-->
    <b-table
      class="mb-1"
      striped
      :fields="addressTableColumns"
      :items="[verificationAddress]"
      @row-clicked="addressTableRowClickHandler"
    >
      <template #cell(street)="data">
        {{ data.item.street ? data.item.street : '---' }}
      </template>
      <template #cell(city)="data">
        {{ data.item.city ? data.item.city : '---' }}
      </template>
      <template #cell(state.name)="data">
        {{ data.item.state ? data.item.state.name : '---' }}
      </template>
      <template #cell(type)="data">
        {{ data ? getTypeText(data.item) : '---' }}
      </template>
    </b-table>
    <!-- Contacts Table Modal   -->
    <b-modal
      id="customer-verification-contact-info_modal"
      centered
      hide-header
      body-class="customer-verification-contact-info_modal-modal-body px-2 py-2"
      footer-class="customer-verification-contact-info_modal-modal-footer"
      ok-variant="success"
      ok-title="Hide"
      ok-only
      size="lg"
      modal-class="customer-verification-contact-info_modal"
    >
      <BRow>
        <BCol>
          <h5 class="d-flex flex-column">
            <b style="margin-bottom: 8px">{{ $t('First Name') }}</b>{{ contactTableRowData.first_name }}
          </h5>
          <h5 class="d-flex flex-column">
            <b style="margin-bottom: 8px">{{ $t('Primary Phone Number') }}</b>{{ contactTableRowData.cell_no }}
          </h5>
        </BCol>
        <BCol>
          <h5 class="d-flex flex-column">
            <b style="margin-bottom: 8px">{{ $t('Middle Name') }}</b>{{ contactTableRowData.middle_name ? contactTableRowData.middle_name : 'no' }}
          </h5>
          <h5 class="d-flex flex-column">
            <b style="margin-bottom: 8px">{{ $t('Additional Phone Number') }}</b>{{ contactTableRowData.phone_no ? contactTableRowData.phone_no : 'no' }}
          </h5>
        </BCol>
        <BCol>
          <h5 class="d-flex flex-column">
            <b style="margin-bottom: 8px">{{ $t('Last Name') }}</b>{{ contactTableRowData.last_name }}
          </h5>
          <h5 class="d-flex flex-column">
            <b style="margin-bottom: 8px">{{ $t('Email') }}</b>{{ contactTableRowData.email }}
          </h5>
        </BCol>
      </BRow>

    </b-modal>
    <!-- Address Table Modal   -->
    <b-modal
      id="customer-verification-address-info_modal"
      centered
      hide-header
      body-class="customer-verification-address-info_modal-modal-body px-2 py-2"
      footer-class="customer-verification-address-info_modal-modal-footer"
      ok-variant="success"
      ok-title="Hide"
      ok-only
      size="lg"
      modal-class="customer-verification-address-info_modal"
    >
      <BRow>
        <BCol>
          <h5 class="d-flex flex-column">
            <b style="margin-bottom: 8px">{{ $t('City') }}</b>{{ addressTableRowData.city ? addressTableRowData.city : 'no' }}
          </h5>
          <h5 class="d-flex flex-column">
            <b style="margin-bottom: 8px">{{ $t('Billing') }}</b>{{ addressTableRowData.billing ? 'yes' : 'no' }}
          </h5>
          <h5 class="d-flex flex-column">
            <b style="margin-bottom: 8px">{{ $t('Line2') }}</b>{{ addressTableRowData.line2 ? addressTableRowData.line2 : 'no' }}
          </h5>
        </BCol>
        <BCol>
          <h5 class="d-flex flex-column">
            <b style="margin-bottom: 8px">{{ $t('State') }}</b>{{ addressTableRowData.state_id ? addressTableRowData.state_id : 'no' }}
          </h5>
          <h5 class="d-flex flex-column">
            <b style="margin-bottom: 8px">{{ $t('Shipping') }}</b>{{ addressTableRowData.billing ? 'yes' : 'no' }}
          </h5>
          <h5 class="d-flex flex-column">
            <b style="margin-bottom: 8px">{{ $t('Zip') }}</b>{{ addressTableRowData.zip ? addressTableRowData.zip : 'no' }}
          </h5>
        </BCol>
        <BCol>
          <h5 class="d-flex flex-column">
            <b style="margin-bottom: 8px">{{ $t('Street') }}</b>{{ addressTableRowData.street ? addressTableRowData.street : 'no' }}
          </h5>
          <h5 class="d-flex flex-column">
            <b style="margin-bottom: 8px">{{ $t('Shipping') }}</b>{{ addressTableRowData.account_holder ? 'yes' : 'no' }}
          </h5>
          <h5 class="d-flex flex-column">
            <b style="margin-bottom: 8px">{{ $t('Verified') }}</b>{{ addressTableRowData.verified ? 'yes' : 'no' }}
          </h5>
        </BCol>
      </BRow>
    </b-modal>
  </div>
</template>

<script>
import { BTable, BCol, BRow } from 'bootstrap-vue'
import config from '../../../verificationConfigs'

export default {
  name: 'AccountHolderInformation',
  components: {
    BTable,
    BCol,
    BRow,
  },
  data() {
    return {
      contactTableRowData: {},
      addressTableRowData: {},
    }
  },
  computed: {
    verificationAddress() {
      return this.$store.state[this.MODULE_NAME].addressTable ?? {}
    },
    verificationContact() {
      return this.$store.state[this.MODULE_NAME].contactTable ?? {}
    },
    verificationVerified() {
      return this.$store.state[this.MODULE_NAME].verification.insurance_policy ? this.$store.state[this.MODULE_NAME].verification.insurance_policy.verified : false
    },
  },
  methods: {
    showModal(modalName) {
      if (this.modals.includes(modalName)) {
        this.$bvModal.show(modalName)
      }
    },
    contactTableRowClickHandler(rowData) {
      this.contactTableRowData = rowData
      this.showModal('customer-verification-contact-info_modal')
    },
    addressTableRowClickHandler(rowData) {
      this.addressTableRowData = rowData
      this.showModal('customer-verification-address-info_modal')
    },
    getTypeText(data) {
      const arr = []
      if (data.billing) arr.push('Billing')
      if (data.shipping) arr.push('Shipping')
      if (data.account_holder) arr.push('Account Holder')
      if (data.can_place_orders) arr.push('Can Place Orders')
      return arr.join(' , ')
    },
    getTypeTextContact(data) {
      if (data) {
        const { contact_types } = data.item

        return contact_types?.map(contactType => contactType.name).join(', ')
      }
      return []
    },
    getPhoneNumber(obj) {
      if (obj.cell_no_object) {
        const data = JSON.parse(obj.cell_no_object)
        return `${data.formatNational}`
      }
      return obj.cell_no
    },
  },
  setup() {
    const MODULE_NAME = 'verification'
    const MODULE_NAME_CLONE = 'cloneData'
    const {
      fields, addressTableColumns, contactTableColumns, modals,
    } = config()
    return {
      modals,
      fields,
      MODULE_NAME,
      MODULE_NAME_CLONE,
      addressTableColumns,
      contactTableColumns,
    }
  },
}
</script>

<style scoped>

</style>
