<template>
  <div>
    <div
      class="d-flex align-items-center"
      style="gap: 10px; margin-bottom: 8px"
    >
      <feather-icon
        :icon="checkVerificationStatus()"
        size="16"
        :class="`${checkVerificationStatus() === 'LNoIcon' ? 'customer-status-icon':''}`"
      />
      <h5 class="font-weight-bolder mb-0">
        {{ $t('ID Scan DMV Check') }}
      </h5>
    </div>
    <div
      v-if="!verification.customer.verification || !IDScanDMV"
      class="d-flex justify-content-center mb-2 border align-items-center"
      style="border-radius: 5px; height: 36px"
    >
      <p
        class="d-flex align-items-center mb-0 text-warning"
        style="gap: 8px; opacity: 0.5"
      >
        <span>
          {{ $t('not validated yet') | capitalize }}
        </span>
      </p>
    </div>
    <b-card
      v-else
      :class="`${(IDScanDMV && IDScanDMV[IF_ID_SCAN_VALID_HAS_KEY]) ? 'adscan-card' : ''}`"
      no-body
    >
      <b-row v-if="(IDScanDMV && IDScanDMV[IF_ID_SCAN_VALID_HAS_KEY])">
        <b-col
          v-for="{ id, title, labelKeyArr, getFromThisObject, getFromExtraThisObject } in [personalInformation, addressInID, validationStatus, IDInformation]"
          :key="id"
          cols="6"
        >
          <div class=" mb-2">
            <h6 class="font-weight-bolder mb-2">
              {{ $t(title) }}
            </h6>
            <div
              v-for="{ label, key } in labelKeyArr"
              :key="key"
              class="px-1"
            >
              <b-row>
                <b-col cols="6">
                  <b>{{ label }}</b>
                </b-col>
                <b-col cols="6">
                  <div>
                    <span v-if="id === 'validation-status'">{{ IDScanDMV[getFromThisObject][key] ? 'Yes' : 'No' | capitalize }}</span>
                    <span v-else>{{ IDScanDMV[getFromThisObject][key] ? IDScanDMV[getFromThisObject][key] : '---' | capitalize }}</span>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div
              v-if="getFromExtraThisObject"
              class="px-1"
            >
              <div
                v-for="(obj, idx) in IDScanDMV[getFromExtraThisObject]"
                :key="idx"
              >
                <b-row>
                  <b-col cols="6">
                    <div class="font-weight-bolder">
                      {{ obj.displayName }} {{ $t('Validation') }}
                    </div>
                    <div class="font-weight-bolder">
                      {{ $t('Reason') }}
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div>{{ obj.statusString ? obj.statusString : '---' }} </div>
                    <div>{{ obj.reason ? splitReason(obj.reason) : '---' }} </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <div v-if="IDScanDMV && IDScanDMV[INVALID_DATA_ERRORS].length > 0">
        <div
          class="d-flex justify-content-center mb-2 border align-items-center"
          style="border-radius: 5px; height: 36px"
        >
          <p
            class="d-flex align-items-center mb-0 text-danger"
            style="gap: 8px; opacity: 0.8"
          >
            <span
              v-for="({ message }, idx) in IDScanDMV[INVALID_DATA_ERRORS]"
              :key="idx"
            >
              {{ message | capitalize }}
              <span v-if="idx !== IDScanDMV[INVALID_DATA_ERRORS].length - 1">,</span>
            </span>
          </p>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import tableConfig from './tableConfig'

export default {
  name: 'IDScanTable',
  components: {
    BCard,
    BRow,
    BCol,
  },
  computed: {
    IDScanDMV() {
      const objDMV = this.$store.state[this.MODULE_NAME].idScanResponse
      return objDMV.dvs
    },
    verification() {
      return this.$store.state[this.MODULE_NAME].verification
    },
  },
  methods: {
    checkVerificationStatus() {
      const status = +this.IDScanDMV?.documentSuccess ?? false

      if (this.IDScanDMV && this.IDScanDMV[this.INVALID_DATA_ERRORS]?.length > 0) {
        return 'LNoIcon'
      }
      // eslint-disable-next-line no-nested-ternary
      return status ? 'LTickIcon' : 'LWarningIcon'
    },
    splitReason(reason) {
      if (reason) {
        const splittedReason = reason.split(';')
        console.log(splittedReason)
        return splittedReason[0]
      }
      return '---'
    },
  },
  setup() {
    const MODULE_NAME = 'verification'
    const {
      personalInformation, addressInID, IDInformation, validationStatus, IF_ID_SCAN_VALID_HAS_KEY, INVALID_DATA_ERRORS,
    } = tableConfig()

    return {
      MODULE_NAME,
      addressInID,
      IDInformation,
      validationStatus,
      INVALID_DATA_ERRORS,
      personalInformation,
      IF_ID_SCAN_VALID_HAS_KEY,
    }
  },
}
</script>
<style lang="scss">
.adscan-card{
  padding: 12px 10px;
  border: 1px solid #DDE3E7;
  border-radius: 5px;
  min-height: 480px;
}

.custom-style-table-idscan {
  & tbody tr td:first-child {
    padding-left: 23px !important;
  }
}
</style>
