<template>
  <div>
    <h4 class="font-weight-bolder mb-1">
      {{ $t('Verification Services') }}
    </h4>
    <emailage-table />
    <!--    <trans-union-t-l-oxp-table />-->
    <!--    <telesign-table />-->
    <i-d-scan-table />
    <i-d-scan-table-identifraud />
    <i-d-scan-table-criminal />
    <u-s-p-s-table />
  </div>
</template>

<script>

// Components
import IDScanTableIdentifraud
from '@/views/main/customers/view/verification/components/VerificationForm/Tables/IDScanTableIdentifraud.vue'
import IDScanTableCriminal
from '@/views/main/customers/view/verification/components/VerificationForm/Tables/IDScanTableCriminal.vue'
import EmailageTable from './Tables/EmailageTable.vue'
import IDScanTable from './Tables/IDScanTable.vue'
import TelesignTable from './Tables/TelesignTable.vue'
import TransUnionTLOxpTable from './Tables/TransUnionTLOxpTable.vue'
import USPSTable from './Tables/USPSTable.vue'

import config from '../../verificationConfigs'

export default {
  name: 'VerificationResultsTables',
  components: {
    IDScanTableCriminal,
    IDScanTableIdentifraud,
    EmailageTable,
    IDScanTable,
    TelesignTable,
    TransUnionTLOxpTable,
    USPSTable,
  },
  data() {
    return {
      verificationsList: [
        // {
        //   name: 'TransUnion TLOxp',
        //   modalName: 'customer-verification-trans-union-tloxp-response-info_modal',
        //   hasResponse: !Object.keys(this.$store.state[this.MODULE_NAME].transUnionTLOxp).length,
        // },
        {
          name: 'Telesign',
          modalName: 'customer-verification-tele-sign-response-info_modal',
          hasResponse: !Object.keys(this.$store.state[this.MODULE_NAME].teleSignResponse).length,
        },
        {
          name: 'ID Scan',
          modalName: 'customer-verification-id-scan-response-info_modal',
          hasResponse: !Object.keys(this.$store.state[this.MODULE_NAME].idScanResponse).length,
        },
        {
          name: 'Emailage',
          modalName: 'customer-verification-emailage_response-info_modal',
          hasResponse: !Object.keys(this.$store.state[this.MODULE_NAME].emailAgeResponse).length,
        },
        {
          name: 'USPS',
          modalName: 'customer-verification-usps_response-info_modal',
          hasResponse: !Object.keys(this.$store.state[this.MODULE_NAME].uspsResponse).length,
        },
      ],
    }
  },
  computed: {
    verificationEmailage() {
      for (const [key, val] of Object.entries(this.$store.state[this.MODULE_NAME].emailAgeResponse)) {
        if (key === 'firstVerificationDate' || key === 'lastVerificationDate' || key === 'domainAge') {
          const date = new Date(val)
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.$store.state[this.MODULE_NAME].emailAgeResponse[key] = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} : ${date.getHours()} : ${date.getMinutes()}`
        }
      }

      return Object.entries(this.$store.state[this.MODULE_NAME].emailAgeResponse)
    },
  },
  methods: {
    showModal(modalName) {
      if (this.modals.includes(modalName)) {
        this.$bvModal.show(modalName)
      }
    },
  },
  setup() {
    const MODULE_NAME = 'verification'

    const {
      addressTableColumns, contactTableColumns, modals,
    } = config()
    return {
      modals,
      MODULE_NAME,
      addressTableColumns,
      contactTableColumns,
    }
  },
}
</script>
