<template>
  <div>
    <h5 class="font-weight-bolder">
      {{ $t('USPS') }}
    </h5>
    <b-table
      class="mb-1 table--custom-style"
      :fields="USPSTableColumns"
      :items="[USPS]"
    >
      <template #cell(action)="">
        <div
          class="d-flex justify-content-center"
        >
          <Can
            :i="ACCESS_ABILITY_VERIFICATION.action"
            :a="ACCESS_ABILITY_VERIFICATION.subject"
          >
            <feather-icon
              icon="LEyeIcon"
              size="24"
              class="cursor-pointer border-dotted"
              style="width: 32px; height: 26px; padding: 5px"
              @click="showModal()"
            />
          </Can>
        </div>
      </template>
      <template #cell(firm_name)="data">
        {{ data.item.FirmName ? data.item.FirmName : '---' }}
      </template>
      <template #cell(address)="data">
        {{ data.item.Address2 ? data.item.Address2 : '---' }}
      </template>
      <template #cell(city)="data">
        {{ data.item.City ? data.item.City : '---' }}
      </template>
      <template #cell(state)="data">
        {{ data.item.State ? data.item.State : '---' }}
      </template>
      <template #cell(zip5)="data">
        {{ data.item.Zip5 ? data.item.Zip5 : '---' }}
      </template>
      <template #cell(zip4)="data">
        {{ data.item.Zip4 ? data.item.Zip4 : '---' }}
      </template>
      <template #cell(domain_relevant_info)="{ data }">
        {{ '---' }}
      </template>
    </b-table>
    <b-modal
      id="customer-verification-usps_response-info_modal"
      centered
      hide-header
      body-class="customer-verification-usps_response-info_modal-body px-2 py-2"
      footer-class="customer-verification-usps_response-info_modal-footer"
      ok-variant="success"
      ok-title="Hide"
      ok-only
      size="xl"
      modal-class="customer-verification-usps_response-info_modal"
    >
      <h3 class="text-center font-weight-bolder mb-2">
        USPS
      </h3>
      <div class="d-flex flex-wrap">
        <div
          v-for="[key, val] of Object.entries(USPS)"
          :key="key"
          class="d-flex w-25"
        >
          <h5 class="d-flex flex-column">
            <b style="text-transform: uppercase; margin-right: 5px">{{ key }}:</b>
          </h5>
          <h5>{{ val ? val : 'No' }}</h5>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>

import { BTable } from 'bootstrap-vue'

import config from '@/views/main/customers/view/verification/verificationConfigs'

export default {
  name: 'USPSTable',
  components: {
    BTable,
  },
  computed: {
    USPS() {
      return this.$store.state[this.MODULE_NAME].uspsResponse
    },
  },
  methods: {
    showModal() {
      this.$bvModal.show('customer-verification-usps_response-info_modal')
    },
  },
  setup() {
    const MODULE_NAME = 'verification'

    const { USPSTableColumns, ACCESS_ABILITY_VERIFICATION } = config()
    return {
      MODULE_NAME,
      USPSTableColumns,
      ACCESS_ABILITY_VERIFICATION,
    }
  },
}
</script>
<style lang="scss">
.table--custom-style {
  & thead tr th  {
    padding: 0 5px !important;
  }
  & tbody tr td  {
    padding: 9.5px 5px !important;
  }
}
</style>
