<template>
  <div>
    <h4 class="font-weight-bolder mb-1">
      <feather-icon
        :icon="verificationInsurancePolicyIsVerified ? 'LTickIcon': 'LWarningIcon'"
        size="20"
      />
      <span>
        {{ $t('Insurance Policy') }}
      </span>
    </h4>
    <b-row>
      <b-col md="6">
        <component
          :is="fields['allow_without_certificate'].type"
          :ref="`${'allow_without_certificate'}_ref`"
          v-model="verification['allow_without_certificate']"
          v-bind="getProps('allow_without_certificate')"
          :is-editable="!$can(ACCESS_ABILITY_VERIFICATION.action,ACCESS_ABILITY_VERIFICATION.subject) || isEditable"
          @change="changeOptionalsOfFields($event)"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <component
          :is="fields[field].type"
          v-for="field in ['policy_number', 'insurance_company_name', 'amount_insured', 'deductible']"
          :key="field"
          :ref="`${field}_ref`"
          v-model="verification[field]"
          v-bind="getProps(field)"
          :is-required-field="isRequired"
          :is-editable="checkEditable"
        />
      </b-col>
      <b-col md="6">
        <label :class="`pl-1 ${isRequired ? 'font-weight-bolder': ''}`">{{ $t('Coverage') }}</label>
        <div
          class="verification-data-pickers"
        >
          <div class="verification-data-pickers__wrapper d-flex">
            <component
              :is="fields[field].type"
              v-for="field in ['coverage_start_date', 'coverage_end_date']"
              :key="field"
              :ref="`${field}_ref`"
              v-model="verification[field]"
              class="w-100"
              v-bind="getProps(field)"
              :is-editable="checkEditable"
            />
          </div>
        </div>
        <div>
          <label :class="`pl-1 ${isRequired ? 'font-weight-bolder': ''}`">{{ $t('Certificate of Insurance') }}</label>
          <verification-policy-upload :is-editable="checkEditable" />
        </div>

        <component
          :is="fields['verified'].type"
          :ref="`${'verified'}_ref`"
          v-model="verification['verified']"
          class="verification-checkbox"
          v-bind="getProps('verified')"
          :is-required-field="isRequired"
          :is-editable="checkEditable"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
// Components
import { BCol, BRow } from 'bootstrap-vue'
import config from '@/views/main/customers/view/verification/verificationConfigs'
import { ref } from '@vue/composition-api'
import { can } from '@core/libs/acl/utils'
import VerificationPolicyUpload from './VerificationPolicyUpload.vue'

// Configs

export default {
  name: 'InsurancePolicy',
  components: {
    BCol,
    BRow,
    VerificationPolicyUpload,
  },
  props: {
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checkEditable() {
      return !can(this.ACCESS_ABILITY_VERIFICATION.action, this.ACCESS_ABILITY_VERIFICATION.subject) ? !can(this.ACCESS_ABILITY_VERIFICATION.action, this.ACCESS_ABILITY_VERIFICATION.subject) : (this.isEditable || this.isEditableLocal)
    },
    verification() {
      return this.$store.state[this.MODULE_NAME].verificationForm
    },
    verificationInsurancePolicyIsVerified() {
      return this.$store.state[this.MODULE_NAME].verificationForm.insurance_policy ? this.$store.state[this.MODULE_NAME].verificationForm.insurance_policy.verified : false
    },
  },
  created() {
    if (this.verification.allow_without_certificate) {
      this.changeOptionalsOfFieldsHandler(false, null)
    }
  },
  methods: {
    getProps(fieldName) {
      const fieldProps = {
        is: this.fields[fieldName].type,
        field: this.fields[fieldName],
        name: fieldName,
      }

      if (this.changeAbleInputFieldsOnRequiredRule.includes(fieldName)) {
        fieldProps.isEditableLocal = this.isRequired
      }

      return fieldProps
    },
    changeOptionalsOfFieldsHandler(bool, rules) {
      this.isRequired = bool
      this.isEditableLocal = !bool
      // eslint-disable-next-line no-unused-vars
      Object.entries(this.fields).forEach(([key, _]) => {
        if (this.changeAbleInputFieldsOnRequiredRule.includes(key)) {
          // eslint-disable-next-line no-param-reassign
          this.fields[key].rules = rules
        }
      })
    },
    changeOptionalsOfFields(event) {
      if (event) {
        this.changeOptionalsOfFieldsHandler(false, null)
      } else {
        this.changeOptionalsOfFieldsHandler(true, 'required')
      }
    },
  },
  setup() {
    const MODULE_NAME = 'verification'
    const {
      fields, CALENDAR_CONFIG, changeAbleInputFieldsOnRequiredRule, ACCESS_ABILITY_VERIFICATION,
    } = config()
    const isRequired = ref(true)
    const isEditableLocal = ref(false)

    return {
      fields,
      isEditableLocal,
      isRequired,
      MODULE_NAME,
      CALENDAR_CONFIG,
      changeAbleInputFieldsOnRequiredRule,
      ACCESS_ABILITY_VERIFICATION,
    }
  },
}
</script>
<style lang="scss">
.verification-data-pickers {
  &__wrapper {

    label {
      display: none !important;
    }

      & .form-group:first-child .form-control {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
      & .form-group:last-child .form-control {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-left: none;
      }
    }

}
</style>
